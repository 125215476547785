import React, { useEffect, useRef, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import style2 from '../../../../../assets/styles/createQuestion.module.css';
import style1 from '../../../../../assets/styles/modalStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_AUTHOR,
  GET_BROWSE_QUESTION,
  GET_CATEGORY,
  GET_FREE_TAG,
  GET_QUESTIONBANK,
  GET_QUESTION_BANK,
  GET_SUBJECT_TAG,
} from '../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../action/apiActions';
import moment from 'moment';
import { Get_All_LOD } from '../../../../../redux/action';

const BrowsingQuestion = ({ SetSelectIds, setModal, totalQuestion, selectIds, selectedIds, AddToTestPaper }) => {
  // _________ PRIMARY TAG STATE INITIALIZATION _________
  const [multisubjectTaginput, setmultiSubjectTaginput] = useState('');
  const [multisubjectTag, setmultiSubjectTag] = useState([]);
  const [multisubjectTagStatus, setmultiSubjectTagStatus] = useState(false);
  // const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagSubjectMultiple = async (e) => {
    setmultiSubjectTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + '?keyword=' + e);
      // console.log(data)
      if (data.status && data.res.status === 200) {
        setmultiSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiSubjectTagStatus(true);
        } else {
          setmultiSubjectTagStatus(false);
        }
      }
    } else {
      setmultiSubjectTagStatus(false);
    }
  };

  // select tags from options
  const selectSubjectTag = (e) => {
    setmultiSelectedSubjectTag((current) => [...current, e]);
    setmultiSubjectTagStatus(false);
    setmultiSubjectTaginput('');
  };

  // _________ Free TAG STATE INITIALIZATION _________
  const [multiFreeTaginput, setmultiFreeTaginput] = useState('');
  const [multiFreeTag, setmultiFreeTag] = useState([]);
  const [multiFreeTagStatus, setmultiFreeTagStatus] = useState(false);
  // const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e) => {
    setmultiFreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
      // console.log(data)
      if (data.status && data.res.status === 200) {
        setmultiFreeTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiFreeTagStatus(true);
        } else {
          setmultiFreeTagStatus(false);
        }
      }
    } else {
      setmultiFreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e) => {
    setmultiSelectedFreeTag((current) => [...current, e]);
    setmultiFreeTagStatus(false);
    setmultiFreeTaginput('');
  };

  // _________ Category TAG STATE INITIALIZATION _________
  const [multiCategoryTaginput, setmultiCategoryTaginput] = useState('');
  const [multiCategoryTag, setmultiCategoryTag] = useState([]);
  const [multiCategoryTagStatus, setmultiCategoryTagStatus] = useState(false);
  // const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagCategoryMultiple = async (e) => {
    setmultiCategoryTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_CATEGORY + '?keyword=' + e);
      // console.log(data)
      if (data.status && data.res.status === 200) {
        setmultiCategoryTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiCategoryTagStatus(true);
        } else {
          setmultiCategoryTagStatus(false);
        }
      }
    } else {
      setmultiCategoryTagStatus(false);
    }
  };

  // select tags from options
  const selectCategoryTag = (e) => {
    setmultiSelectedCategoryTag((current) => [...current, e]);
    setmultiCategoryTagStatus(false);
    setmultiCategoryTaginput('');
  };

  // _________ AUTHIR TAG STATE INITIALIZATION _________
  const [multiAuthorTaginput, setmultiAuthorTaginput] = useState('');
  const [multiAuthorTag, setmultiAuthorTag] = useState([]);
  const [multiAuthorTagStatus, setmultiAuthorTagStatus] = useState(false);
  // const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagAuthorMultiple = async (e) => {
    setmultiAuthorTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_AUTHOR + '?keyword=' + e);
      // console.log(data)
      if (data.status && data.res.status === 200) {
        setmultiAuthorTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiAuthorTagStatus(true);
        } else {
          setmultiAuthorTagStatus(false);
        }
      }
    } else {
      setmultiAuthorTagStatus(false);
    }
  };

  // select tags from options
  const selectAuthorTag = (e) => {
    setmultiSelectedAuthorTag((current) => [...current, e]);
    setmultiAuthorTagStatus(false);
    setmultiAuthorTaginput('');
  };

  // Get the current year
  const currentYear = new Date().getFullYear();
  // _________ YEAR USED-> STATE INITIALIZATION _________

  // Get the current year
  // const currentYear = new Date().getFullYear();

  // Initialize state for the selected year
  // const [selectedYear, setSelectedYear] = useState(currentYear);
  // const [selectedYearExclude, setSelectedYearExclude] = useState(currentYear);

  // Create an array of years from 2000 to 20 years after the current year
  const yearOptions = [];
  for (let year = 2000; year <= currentYear + 20; year++) {
    yearOptions.push(year);
  }

  // Event handler for changing the selected year
  const handleYearChangeUsed = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  // Event handler for changing the selected year
  const handleYearChangeExclude = (e) => {
    setSelectedYearExclude(parseInt(e.target.value, 10));
  };

  // _________ PRIMARY TAG STATE INITIALIZATION _________
  const [questionId, setquestionId] = useState('');
  const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([]);
  const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);
  const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([]);
  const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);
  const [sort, setSort] = useState('');
  // Initialize state for the selected year
  const [selectedYear, setSelectedYear] = useState(2000);
  const [selectedYearExclude, setSelectedYearExclude] = useState('');
  // _________ YEAR USED-> STATE INITIALIZATION _________

  // const [currentSort, setCurrentSort] = useState("")

  // ________ PAGINATION ________

  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(5);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // _________question bank id which is top dropdown _________
  const [questionBankId, setQuestionBankId] = useState(null);
  // ________ LOD _________
  const [lod, setLod] = useState('');
  // ________ category ________
  const [category, setCategory] = useState('');

  useEffect(() => {
    // alert(totalCount)
    if (totalCount < 5) {
      setLast(totalCount);
    } else {
      setLast(5);
    }
  }, [totalCount]);

  const handleSort = (e) => {
    if (sort === '') {
      setSort(e);
    } else if (sort === e) {
      setSort(`-${e}`);
    } else {
      setSort(e);
    }
  };

  // ________ PAGINATION ________

  useEffect(() => {
    // alert(totalCount)
    if (totalCount < 5) {
      setLast(totalCount);
    } else {
      setLast(5);
    }
  }, [totalCount]);

  const next = () => {
    if (offset + limit >= totalCount) {
      setOffset(offset);
      setCurrentPage(currentPage);
    } else {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
    }

    if (last + limit > totalCount) {
      setLast(totalCount);
    } else {
      setLast(last + limit);
    }
  };

  const previous = () => {
    if (offset - limit < 0) {
      setOffset(offset);
      setCurrentPage(currentPage);
    } else {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
    }

    if (last <= 5) {
      setLast(last);
    } else {
      setLast((currentPage - 1) * limit);
    }
  };
  // ________ PAGINATION ________

  // _________ALL question created listing data _________
  const [questionResults, setquestionResults] = useState([]);

  const QuestionListing = async () => {
    const url =
      GET_BROWSE_QUESTION +
      '?question_bank=' +
      questionBankId +
      '&category=' +
      category +
      '&subject_tags=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&free_tags=' +
      multiselectedFreeTag.map((item) => item.id).join(',') +
      '&question_authors=' +
      multiselectedAuthorTag.map((item) => item.id).join(',') +
      '&offset=' +
      offset +
      '&limit=' +
      limit +
      '&lod=' +
      lod +
      '&after=' +
      selectedYear +
      '&sort=' +
      sort +
      '&before=' +
      selectedYearExclude +
      '&question_ids=' +
      questionId;

    // console.log("url", url)
    const questionlistingData = await CallWithAuth('GET', url);
    // console.log("questionlistingData", questionlistingData)
    if (questionlistingData.status && questionlistingData.res.status === 200) {
      setquestionResults(questionlistingData.res.data.results);
      setTotalCount(questionlistingData.res.data.count);
    }
  };

  useEffect(() => {
    QuestionListing();
  }, [questionBankId, questionId, offset, sort]);

  const ResetSelection = () => {
    setCategory('');
    setmultiSelectedSubjectTag([]);
    setmultiSelectedFreeTag([]);
    setmultiSelectedAuthorTag([]);
    setOffset(0);
    setLod('');
    setSelectedYear(currentYear);
    setSort('');
    setSelectedYearExclude(currentYear);
    setquestionId(0);
  };

  const [questionBank, setQuestionBank] = useState(null);
  const getQuestionBank = async () => {
    const GetData = await CallWithAuth('GET', GET_QUESTIONBANK);
    // console.log(GetData)
    if (GetData.status && Array.isArray(GetData.res.data)) {
      setQuestionBank(GetData.res.data);
      // setQuestionBankId(GetData.res.data[0].id)
    }
  };

  useEffect(() => {
    getQuestionBank();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Get_All_LOD('', ''));
  }, []);
  const { lodList, categoryList } = useSelector((state) => state.GetAllData);

  // Selection

  const SelectQuestion = (e) => {
    if (selectIds?.includes(e)) {
      alert('check 2');
      const newArray = selectIds.filter((item) => item !== e);
      SetSelectIds(newArray);
    } else {
      SetSelectIds((prevState) => [...prevState, e]);
    }
  };

  // useEffect(() => {
  //   if (Array.isArray(selectIds) && selectIds.length > 0) {
  //     alert("check 1")
  //     SetSelectedId(prevState => [...prevState, ...selectIds])
  //   } else {
  //     SetSelectedId([])
  //   }
  // }, [selectIds])

  // const [selectQuestionId, setselectQuestionId]= useState([])
  useEffect(() => {
    const selectQuestionIds = selectedIds.map((items, i) => items.id);
    // setselectQuestionId(selectQuestionIds)
    SetSelectIds(selectQuestionIds);
  }, []);

  // const AddToTestPaper = () => {
  //   const valuesToAdd = selectIds;
  //   console.log("valuesToAdd",valuesToAdd)
  //   SetSelectIds(prevState => [...prevState, ...valuesToAdd])
  //   setModal(false)
  // }

  const rowRef = useRef();

  // console.log("selectQuestionId",selectIds, questionResults)

  // useEffect(()=>{

  // },[selectQuestionId])

  return (
    <div className={style1.filterModal}>
      <div className={style1.overlay}></div>
      <div className={style1.center_div_larger}>
        <div className={style.test_creation_form}>
          <div className={style.filterContainer}>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }} className={style.test_top_content_table}>
              <div className={style.leftItems}>
                <p style={{ fontWeight: '600', fontSize: '14px' }}>Browsing questions for Group 1 - Section 1</p>
                <div style={{ fontSize: '13px' }} className={style.handleAttempt}>
                  <span>{selectIds.length} </span>
                  <span style={{ color: 'lightgray', paddingRight: '5px' }}>/{totalQuestion}</span>
                  <span>Questions already added</span>
                </div>
              </div>
              <div className={style.table_filter_right_options}>
                <svg
                  onClick={() => setModal(false)}
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.35352 6.34375L17.684 17.6575"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.35216 17.6575L17.6826 6.34375"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className={style.masterfilterCreation}>
              <div className={style.filterCreationArea}>
                {/* filters  */}
                <div className={style.sectionContainer}>
                  <section className={style.workArea1}>
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Question Bank </p>
                        <div className={style.header_drop}>
                          <select
                            name="question"
                            id="question"
                            onClick={(e) => setQuestionBankId(Number(e.target.value))}
                          >
                            <option value={null} hidden>
                              ----- Select Question Bank -----
                            </option>
                            {questionBank?.map((items, i) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* subject tag  */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Subject | Area | Topic | Subtopic </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search} ref={rowRef}>
                            {multiselectedSubjectTag?.map((items, i) => (
                              <>
                                <span className={style.selected_tags}>
                                  {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                </span>
                              </>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multisubjectTaginput}
                              onChange={(e) => TagSubjectMultiple(e.target.value)}
                            />

                            {multisubjectTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multisubjectTag
                                  ?.filter(
                                    (item) => !multiselectedSubjectTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <>
                                      <div onClick={() => selectSubjectTag(items)}>
                                        <span>
                                          {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                        </span>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedSubjectTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* category and tag  */}
                    <div className={style.drop}>
                      {/* <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Category </p>
                          <div className={style.search_listing}>
                            <div className={style.tag_icons}>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z" fill="white" />
                              </svg>


                            </div>

                            <div className={style.input_search}>

                              {multiselectedCategoryTag?.map((items, i) => (
                                <>
                                  <span className={style.selected_tags}>{items.name}  </span>
                                </>
                              ))}

                              <input type='search' id='searchInput' placeholder='' value={multiCategoryTaginput} onChange={(e) => TagCategoryMultiple(e.target.value)} />


                              {multiCategoryTagStatus ?
                                <div className={style.list_input_primary}>
                                  {multiCategoryTag?.filter(item => !multiselectedCategoryTag.some(filterItem => item.id === filterItem.id)).map((items, i) => (
                                    <>
                                      <div onClick={() => selectCategoryTag(items)}><span>{items.name}  </span></div>
                                    </>
                                  ))}
                                </div>
                                : ""}

                            </div>

                            <div className={style.cancel_button}>
                              <button onClick={() => setmultiSelectedCategoryTag([])}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </button>
                            </div>

                          </div>

                        </div>
                      </div> */}

                      <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Difficulty </p>
                          <div className={style.header_drop}>
                            <select name="question" id="question" onClick={(e) => setLod(Number(e.target.value))}>
                              <option value={null} hidden>
                                ----- Select Difficulty -----
                              </option>
                              {lodList?.map((items, i) => (
                                <option value={items.id}>{items.level}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Category </p>
                          <div className={style.header_drop}>
                            <select name="question" id="question" onClick={(e) => setCategory(Number(e.target.value))}>
                              <option value={null} hidden>
                                ----- Select Category -----
                              </option>
                              {categoryList?.map((items, i) => (
                                <option value={items.id}>{items.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={style.drop}>
                      <div className={style.drop_item}>
                        <div className={style.subject}>
                          <p>Include questions used in or after </p>
                          <select
                            name="question"
                            id="question"
                            className={style.header_drop_filter}
                            value={selectedYear}
                            onChange={handleYearChangeUsed}
                          >
                            {yearOptions.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className={style.drop_item}>
                        <div className={style.subject}>
                          <p>Include question used in or before </p>
                          <select
                            name="question"
                            id="question"
                            className={style.header_drop_filter}
                            value={selectedYearExclude}
                            onChange={handleYearChangeExclude}
                          >
                            {yearOptions.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* free tag  */}
                    <div className={style.drop}>
                      <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Tags </p>
                          <div className={style.search_listing}>
                            <div className={style.tag_icons}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path
                                  d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                  fill="white"
                                />
                              </svg>
                            </div>

                            <div className={style.input_search}>
                              {multiselectedFreeTag?.map((items, i) => (
                                <>
                                  <span className={style.selected_tags}>{items.name} </span>
                                </>
                              ))}

                              <input
                                type="search"
                                id="searchInput"
                                placeholder=""
                                value={multiFreeTaginput}
                                onChange={(e) => TagFreeMultiple(e.target.value)}
                              />

                              {multiFreeTagStatus ? (
                                <div className={style.list_input_primary}>
                                  {multiFreeTag
                                    ?.filter(
                                      (item) => !multiselectedFreeTag.some((filterItem) => item.id === filterItem.id)
                                    )
                                    .map((items, i) => (
                                      <>
                                        <div onClick={() => selectFreeTag(items)}>
                                          <span>{items.name} </span>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>

                            <div className={style.cancel_button}>
                              <button onClick={() => setmultiSelectedFreeTag([])}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.75781 7.75781L16.2431 16.2431"
                                    stroke="#272727"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.75691 16.2431L16.2422 7.75781"
                                    stroke="#272727"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* question author  */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Question Author(s) </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search}>
                            {multiselectedAuthorTag?.map((items, i) => (
                              <>
                                <span className={style.selected_tags}>
                                  {items.first_name}-{items.last_name}{' '}
                                </span>
                              </>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multiAuthorTaginput}
                              onChange={(e) => TagAuthorMultiple(e.target.value)}
                            />

                            {multiAuthorTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multiAuthorTag
                                  ?.filter(
                                    (item) => !multiselectedAuthorTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <>
                                      <div onClick={() => selectAuthorTag(items)}>
                                        <span>
                                          {items.first_name}-{items.last_name}{' '}
                                        </span>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedAuthorTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className={style.workArea2}>
                    <span style={{ fontWeight: '500', fontSize: '13px' }}>Question ID(s) </span>

                    <div className={` ${style.filter_listing} ${style.id_filter_listing}`}>
                      <div className={style.filterSearch}>
                        <svg
                          style={{ marginTop: '13px', marginLeft: '10px' }}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="20" height="20" rx="4" fill="#272727" />
                          <path
                            d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                            fill="white"
                          />
                        </svg>
                        <input
                          type="search"
                          id="searchInput"
                          placeholder="Search AuthorSearch Question ID(s)"
                          onChange={(e) => setquestionId(e.target.value)}
                        />
                      </div>
                    </div>
                  </section>
                </div>
                {/* button  */}
                <div style={{ marginTop: '60px' }} className={style.performBT}>
                  <button className={` ${style.btOne} ${style.common}`} onClick={() => ResetSelection()}>
                    Reset Selection
                  </button>
                  <button className={` ${style.btTwo} ${style.common}`} onClick={() => QuestionListing()}>
                    Apply Selected Filter
                  </button>
                </div>
                {/*  ________________________________ question ________________________________ */}
                <div className={style.questionBank}>
                  <div className={style.headerBank}>
                    <h4 style={{ fontWeight: '500' }}>Question List</h4>
                  </div>

                  {/* ________________ question ________________  */}
                  <div style={{ marginTop: '30px' }} className={style.sub_head_content_table}>
                    <table>
                      <thead>
                        <tr>
                          <th className={` ${style.very_first} ${style.pointer}`}>Question ID and Tags</th>

                          <th className={` ${style.second} ${style.pointer}`}>
                            {' '}
                            <span>Author</span>
                            <svg
                              width="19"
                              height="12"
                              viewBox="0 0 19 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                fill="#00ABFB"
                              />
                            </svg>
                          </th>
                          <th className={` ${style.third} ${style.pointer}`}>
                            <span>Created On</span>
                            <svg
                              width="19"
                              height="12"
                              viewBox="0 0 19 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                fill="#00ABFB"
                              />
                            </svg>
                          </th>
                          <th className={` ${style.fourth} ${style.pointer}`}>
                            <span>Type</span>
                            <svg
                              width="19"
                              height="12"
                              viewBox="0 0 19 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                fill="#00ABFB"
                              />
                            </svg>
                          </th>
                          <th className={` ${style.fourth} ${style.pointer}`}>
                            <span>LOD</span>
                            <svg
                              width="19"
                              height="12"
                              viewBox="0 0 19 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                fill="#00ABFB"
                              />
                              <path
                                d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                fill="#00ABFB"
                              />
                            </svg>
                          </th>

                          <th className={` ${style.fifth} ${style.pointer} ${style.filter_th}`}>
                            <span>Select</span>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                fill="url(#paint0_linear_1445_50173)"
                              />
                              <path
                                d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1445_50173"
                                  x1="20.9141"
                                  y1="0"
                                  x2="6.81222"
                                  y2="16.4928"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#9BF763" />
                                  <stop offset="1" stopColor="#25AB5B" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {/* ALL AND MY QUESTION according to ternary operator */}

                      <tbody>
                        {/* .filter(question => !selectIds?.includes(question?.question_id))? */}
                        {questionResults?.map((items, i) => (
                          <tr className={style.table_body_content}>
                            <td>
                              <div className={style.question_id_tag}>
                                <div className={style.id}>{items.question_id}</div>
                                <div className={style.tag}>
                                  <p>{items.question_short_identifier}</p>
                                  <span>{items.primary_tag.slug}</span>
                                </div>
                              </div>
                            </td>
                            {/* <td>
                              <div className={style.general_tag}>
                                {items.free_tags.map((item, i) => (
                                  <button className={style.general_button}>{item.name}</button>
                                ))}
                              </div>
                            </td> */}
                            <td>
                              <div className={style.author_tag}>Author by {items.created_by}</div>
                            </td>
                            <td>
                              <div className={style.created_date}>
                                {' '}
                                {moment(items.created_on).format('Do MMM YYYY')}
                              </div>
                            </td>
                            <td>
                              <div className={style.created_date}> {items.type}</div>
                            </td>
                            <td>
                              <div className={style.lod}> {items.lod}</div>
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                display: 'flex',
                                height: '60px',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {selectIds.length >= totalQuestion ? (
                                ''
                              ) : (
                                <div
                                  style={{ border: '1px solid', height: '20px', width: '20px', borderRadius: '6px' }}
                                  onClick={() => SelectQuestion(items?.question_id)}
                                >
                                  {selectIds?.includes(items?.question_id) ? (
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                        fill="url(#paint0_linear_1445_50173)"
                                      />
                                      <path
                                        d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_1445_50173"
                                          x1="20.9141"
                                          y1="0"
                                          x2="6.81222"
                                          y2="16.4928"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#9BF763" />
                                          <stop offset="1" stopColor="#25AB5B" />
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                            </td>
                            {/* <td>
                              <div className={` ${style.action} ${style.pointer}`}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                    fill="#272727"
                                  />
                                </svg>
                                <div
                                  style={{ display: "none" }}
                                  className={style.action_button}
                                >
                                  <div className={style.edit}>
                                    {" "}
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2 14.668H14"
                                        stroke="#212529"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                        stroke="#212529"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                        stroke="#212529"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>{" "}
                                    <span>Edit</span>
                                  </div>
                                  <div className={style.duplicate}>
                                    <svg
                                      width="13"
                                      height="16"
                                      viewBox="0 0 13 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                        fill="#212529"
                                      />
                                    </svg>
                                    <span> Duplicate</span>
                                  </div>
                                </div>
                              </div>
                            </td> */}
                          </tr>
                        ))}

                        {/* <tr className={style.table_body_content}>
                          <td>
                            <div className={style.question_id_tag}>
                              <div className={style.id}>122314</div>
                              <div className={style.tag}>
                                <p>
                                  For years, movies and television series like Crime
                                  Scene Investi...
                                </p>
                                <span>Maths | Area 6 | Topic 8 | Subtopic 4</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              <button className={style.general_button}>ss</button>
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by saga</div>
                          </td>
                          <td>
                            <div className={style.created_date}>10sept 2024</div>
                          </td>
                          <td>
                            <div className={style.lod}>hard</div>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                fill="url(#paint0_linear_1445_50173)"
                              />
                              <path
                                d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1445_50173"
                                  x1="20.9141"
                                  y1="0"
                                  x2="6.81222"
                                  y2="16.4928"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#9BF763" />
                                  <stop offset="1" stop-color="#25AB5B" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </td>
                          <td>
                            <div className={` ${style.action} ${style.pointer}`}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                  fill="#272727"
                                />
                              </svg>
                              <div
                                style={{ display: "none" }}
                                className={style.action_button}
                              >
                                <div className={style.edit}>
                                  {" "}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>{" "}
                                  <span>Edit</span>
                                </div>
                                <div className={style.duplicate}>
                                  <svg
                                    width="13"
                                    height="16"
                                    viewBox="0 0 13 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                      fill="#212529"
                                    />
                                  </svg>
                                  <span> Duplicate</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr className={style.table_body_content}>
                          <td>
                            <div className={style.question_id_tag}>
                              <div className={style.id}>122314</div>
                              <div className={style.tag}>
                                <p>
                                  For years, movies and television series like Crime
                                  Scene Investi...
                                </p>
                                <span>Maths | Area 6 | Topic 8 | Subtopic 4</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              <button className={style.general_button}>ss</button>
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by saga</div>
                          </td>
                          <td>
                            <div className={style.created_date}>10sept 2024</div>
                          </td>
                          <td>
                            <div className={style.lod}>hard</div>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                fill="url(#paint0_linear_1445_50173)"
                              />
                              <path
                                d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1445_50173"
                                  x1="20.9141"
                                  y1="0"
                                  x2="6.81222"
                                  y2="16.4928"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#9BF763" />
                                  <stop offset="1" stop-color="#25AB5B" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </td>
                          <td>
                            <div className={` ${style.action} ${style.pointer}`}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                  fill="#272727"
                                />
                              </svg>
                              <div
                                style={{ display: "none" }}
                                className={style.action_button}
                              >
                                <div className={style.edit}>
                                  {" "}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>{" "}
                                  <span>Edit</span>
                                </div>
                                <div className={style.duplicate}>
                                  <svg
                                    width="13"
                                    height="16"
                                    viewBox="0 0 13 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                      fill="#212529"
                                    />
                                  </svg>
                                  <span> Duplicate</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr className={style.table_body_content}>
                          <td>
                            <div className={style.question_id_tag}>
                              <div className={style.id}>122314</div>
                              <div className={style.tag}>
                                <p>
                                  For years, movies and television series like Crime
                                  Scene Investi...
                                </p>
                                <span>Maths | Area 6 | Topic 8 | Subtopic 4</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              <button className={style.general_button}>ss</button>
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by saga</div>
                          </td>
                          <td>
                            <div className={style.created_date}>10sept 2024</div>
                          </td>
                          <td>
                            <div className={style.lod}>hard</div>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                fill="url(#paint0_linear_1445_50173)"
                              />
                              <path
                                d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1445_50173"
                                  x1="20.9141"
                                  y1="0"
                                  x2="6.81222"
                                  y2="16.4928"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#9BF763" />
                                  <stop offset="1" stop-color="#25AB5B" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </td>
                          <td>
                            <div className={` ${style.action} ${style.pointer}`}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                  fill="#272727"
                                />
                              </svg>
                              <div
                                style={{ display: "none" }}
                                className={style.action_button}
                              >
                                <div className={style.edit}>
                                  {" "}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>{" "}
                                  <span>Edit</span>
                                </div>
                                <div className={style.duplicate}>
                                  <svg
                                    width="13"
                                    height="16"
                                    viewBox="0 0 13 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                      fill="#212529"
                                    />
                                  </svg>
                                  <span> Duplicate</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr className={style.table_body_content}>
                          <td>
                            <div className={style.question_id_tag}>
                              <div className={style.id}>122314</div>
                              <div className={style.tag}>
                                <p>
                                  For years, movies and television series like Crime
                                  Scene Investi...
                                </p>
                                <span>Maths | Area 6 | Topic 8 | Subtopic 4</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              <button className={style.general_button}>ss</button>
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by saga</div>
                          </td>
                          <td>
                            <div className={style.created_date}>10sept 2024</div>
                          </td>
                          <td>
                            <div className={style.lod}>hard</div>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                fill="url(#paint0_linear_1445_50173)"
                              />
                              <path
                                d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1445_50173"
                                  x1="20.9141"
                                  y1="0"
                                  x2="6.81222"
                                  y2="16.4928"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#9BF763" />
                                  <stop offset="1" stop-color="#25AB5B" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </td>
                          <td>
                            <div className={` ${style.action} ${style.pointer}`}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                  fill="#272727"
                                />
                              </svg>
                              <div
                                style={{ display: "none" }}
                                className={style.action_button}
                              >
                                <div className={style.edit}>
                                  {" "}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>{" "}
                                  <span>Edit</span>
                                </div>
                                <div className={style.duplicate}>
                                  <svg
                                    width="13"
                                    height="16"
                                    viewBox="0 0 13 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                      fill="#212529"
                                    />
                                  </svg>
                                  <span> Duplicate</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>

                  {/* ________________ footer ________________ */}
                  <footer style={{ marginBottom: '10em' }} className={style.sectionFooter}>
                    <div className={style.footer_content}>
                      <span style={{ fontWeight: '500' }}>Selected Questions</span>
                      <span>
                        {selectIds?.length} <b style={{ color: 'lightgray' }}>/{totalQuestion}</b>
                      </span>
                    </div>

                    <div className={`${style.footer_content_mid} ${style.footer_content_last} `}>
                      {selectIds?.map((items, i) => (
                        <span className={style.closeIds}>
                          {items}
                          {/* <svg
                            style={{ marginLeft: "18px", cursor: "pointer" }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.85547 6.34375L17.7151 17.6575"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.85324 17.6575L17.7129 6.34375"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                        </span>
                      ))}
                    </div>

                    <div className={style.footer_content_right}>
                      <div
                        style={{ marginRight: '20px' }}
                        className={style.drop_score}
                        onClick={() => AddToTestPaper()}
                      >
                        Add To Test Paper
                      </div>
                    </div>
                  </footer>
                  {/*______________________________ PAGINATION ______________________________  */}
                  <div className={style2.pagination}>
                    <div className={style2.number}>
                      The Page you are on &nbsp; &nbsp; <button className={style2.currentpage}> {currentPage} </button>
                    </div>
                    <div className={style2.line}>
                      <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line
                          opacity="0.1"
                          x1="0.695312"
                          y1="23.5"
                          x2="0.695311"
                          y2="0.5"
                          stroke="#764FDB"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={style2.change}>
                      <button onClick={() => previous()}>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.5622 12.7803C10.2635 13.0732 9.7793 13.0732 9.48064 12.7803L5.14685 8.53033C4.84819 8.23744 4.84819 7.76256 5.14685 7.46967L9.48064 3.21967C9.7793 2.92678 10.2635 2.92678 10.5622 3.21967C10.8608 3.51256 10.8608 3.98744 10.5622 4.28033L6.76921 8L10.5622 11.7197C10.8608 12.0126 10.8608 12.4874 10.5622 12.7803Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </button>
                      <div className={style2.current}>
                        {offset}-{last} of {totalCount}
                      </div>
                      <button onClick={() => next()}>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.63122 3.21967C6.92989 2.92678 7.41413 2.92678 7.7128 3.21967L12.0466 7.46967C12.3452 7.76256 12.3452 8.23744 12.0466 8.53033L7.7128 12.7803C7.41413 13.0732 6.92989 13.0732 6.63122 12.7803C6.33256 12.4874 6.33256 12.0126 6.63122 11.7197L10.4242 8L6.63122 4.28033C6.33256 3.98744 6.33256 3.51256 6.63122 3.21967Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {/*______________________________ PAGINATION ______________________________  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowsingQuestion;
