import React, { ReactNode } from 'react';
import logo from '../assets/images/logo.png';
import signin from '../assets/images/Frame.png';
import style from '../assets/styles/signin.module.css';

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <div className={style.signup_page}>
      <div className={style.section1}>
        <header className={style.ims_logo}>
          <img className={style.logo} src={logo} alt="ims-logo" width={100} height={100} />
        </header>

        <div className={style.main_area}>
          <div className={style.text_area}>
            <h2 className={style.title}>Welcome!</h2>
            <p className={style.title_text}>
              Enter your registered email address and password
              <br />
              and start creating with us
            </p>
          </div>
          <div className={`${style.title_img}`}>
            <img className={`${style.public_img1}`} src={signin} alt="sign-in" />
          </div>
        </div>
      </div>

      <div className={style.section2}>
        {/****  as per figma commanded this line ****/}
        {/* <div className={style.nav_section}>  
          <nav>
            <span className={style.nav_text}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={style.fa_solid}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.68795 10.4031C5.68795 10.9056 6.1021 11.3077 6.6198 11.3077C7.14489 11.3077 7.53686 10.8985 7.54426 10.4031C7.54426 9.89333 7.14489 9.50564 6.6198 9.50564C6.1021 9.50564 5.68795 9.89333 5.68795 10.4031ZM8.0313 6.90089C8.27555 6.67029 8.55681 6.40475 8.8459 5.99487C9.34881 5.26974 9.15652 4.30051 8.83111 3.78359C8.44653 3.17333 7.69957 2.69231 6.60501 2.69231C5.62878 2.69231 4.87442 3.15897 4.42329 3.79795C4.18662 4.12821 4.32714 4.58769 4.71911 4.73846C5.02234 4.86051 5.34774 4.73846 5.53264 4.48C5.74711 4.17128 6.09471 3.91282 6.6198 3.91282C7.8179 3.91282 8.05456 5.0041 7.63301 5.58564C7.45469 5.83247 7.21888 6.04352 6.9802 6.25713C6.68432 6.52194 6.38404 6.79068 6.18346 7.13641C6.04294 7.38051 5.98377 7.6318 5.96159 7.8759C5.92461 8.24923 6.22783 8.56513 6.61241 8.56513H6.65678C6.98958 8.56513 7.28541 8.32821 7.32239 8.00513C7.34458 7.78256 7.38895 7.68205 7.46291 7.55282C7.60299 7.30525 7.80064 7.11865 8.0313 6.90089Z"
                  fill="#8E9EAB"
                />
              </svg>
              Need Help ?
            </span>
            <span className={style.nav_text}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46013 0.75H1.85526C1.24484 0.75 0.75 1.24484 0.75 1.85526C0.75 7.04383 4.95617 11.25 10.1447 11.25C10.7552 11.25 11.25 10.7552 11.25 10.1447V8.53987C11.25 8.06282 10.9596 7.63382 10.5166 7.45665L8.97915 6.84166C8.58128 6.68251 8.12818 6.75429 7.79898 7.02863L7.40181 7.3596C6.93826 7.74589 6.25664 7.71497 5.82997 7.2883L4.7117 6.17003C4.28503 5.74336 4.25411 5.06174 4.6404 4.59819L4.97137 4.20102C5.24571 3.87182 5.31749 3.41872 5.15834 3.02085L4.54335 1.48338C4.36618 1.04044 3.93718 0.75 3.46013 0.75Z"
                  fill="#8E9EAB"
                />
              </svg>
              Contact us
            </span>
          </nav>
        </div> */}

        <div className={style.signup_form}>{children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
