import { useEffect, useRef, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_FREE_TAG } from '../../../../../action/apiPath';
import { useDispatch, useSelector } from 'react-redux';
import { Get_All_Category, Get_Product_Type, Task_of_User } from '../../../../../redux/action';
import QuillEditor from '../../../../atoms/QuillEditor';

const BasicDeatils = () => {
  const rowRef = useRef();
  const dispatch = useDispatch();

  const { testDetails } = useSelector((state) => state.GetAllData);
  const { getProductType } = useSelector((state) => state.GetAllData);
  const { categoryList } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(Get_All_Category('', ''));
    dispatch(Task_of_User(0));
    dispatch(Get_Product_Type());
  }, []);

  //_______________________________________________ Creation of new test _________________________
  const [multiselectedfreeTag, setmultiSelectedfreeTag] = useState([]);
  const [newFreeTag, setnewFreeTag] = useState([]);
  //_______________ Fn for handle free tags {

  // ________________ Initialization of FREE TAG ________________//
  const [multifreeTag, setmultifreeTag] = useState([]);
  const [multifreeTagStatus, setmultifreeTagStatus] = useState(false);
  const [newfreeTags, setnewfreeTags] = useState([]);
  const [newfreeTagStatus, setnewfreeTagStatus] = useState(false);

  // console.log("testDetails", testDetails)

  const decodeBase64 = (base64String) => {
    return atob(base64String);
  };

  function b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  return (
    <div className={style.masterTestCreation}>
      <div className={style.testCreationArea}>
        <div className={style.bound}>
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Test Paper Rendering Template </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  name="question"
                  value={getProductType?.filter((item) => item.id == testDetails?.rendering_template)[0].product_type}
                  disabled
                  id="question"
                  className={style.template_drop_filter}
                >
                  <option>----- Select Rendering Template -----</option>
                  {getProductType?.map((items, i) => (
                    <option>{items.product_type}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Test Name </span>
                <b className={style.red_mendantory}>*</b>
                <input disabled value={testDetails.test_name} placeholder="" className={style.template_drop_filter} />
              </div>
            </div>
          </div>
        </div>

        <div className={style.bound}>
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Academic Year </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  disabled
                  value={testDetails.academic_year}
                  name="question"
                  id="question"
                  className={style.template_drop_filter}
                >
                  <option>{testDetails.academic_year}</option>
                </select>
              </div>
            </div>
          </div>

          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Category </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  disabled
                  value={testDetails?.category}
                  name="question"
                  id="question"
                  className={style.template_drop_filter}
                >
                  {categoryList?.map((items, i) => (
                    <option>{items.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className={style.templateDrop}>
          <div className={style.templateDrop_item}>
            <div className={style.templateGroup}>
              <span>Free Form Tags </span>
              <b className={style.red_mendantory}>*</b>
              <div className={style.search_listing}>
                <div className={style.tag_icon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M18.2598 19.2592L16.1406 16.8594"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <span>Tags</span>
                </div>
                {/* this is input field for searching */}
                <div className={style.input_search} ref={rowRef}>
                  {/* multifreeTag selected one  */}
                  {multiselectedfreeTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>{items.name} </span>
                    </>
                  ))}

                  {/* new free tag selected one  */}
                  {newFreeTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>{items} </span>
                    </>
                  ))}

                  {/* input field  */}
                  <input
                    disabled
                    type="search"
                    id="searchInput"
                    placeholder=""
                    value={testDetails.free_tags?.map((tag) => tag.name)?.join(', ')}
                  />

                  {/* list of items of pre-defined multifree tag  */}
                  {multifreeTagStatus ? (
                    <div className={style.list_input_primary}>
                      {multifreeTag?.map((items, i) => (
                        <>
                          <div className={style.pointer}>
                            <span>{items.name} </span>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}

                  {/* list of item of user-defined multifreetag  */}
                  {newfreeTagStatus ? (
                    <div className={style.list_input_primary}>
                      {newfreeTags?.map((items, i) => (
                        <>
                          <div className={style.pointer}>
                            <span>{items} </span>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* cancel button  */}
                <div className={style.cancel_button}>
                  <button disabled className={style.pointer} type="cancel">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {testDetails?.rendering_template === 2 && (
          <>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span>Number of Question to Pass </span>
                  <b className={style.red_mendantory}>*</b>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    disabled
                    value={testDetails.number_of_questions_to_pass}
                    placeholder=""
                    className={style.template_drop_filter}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className={style.templateDrop}>
          <hr className={style.hrLine} />

          <div className={style.pageTwoContainer}>
            <h3 className={style.testMarklabel}>Group Marking Details</h3>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span>Marking Scheme </span>
                  <b className={style.red_mendantory}>*</b>
                  <select
                    disabled
                    value={testDetails.marking_scheme}
                    name="question"
                    id="question"
                    className={style.template_drop_filter}
                  >
                    <option value={0}>Regular -Per Question</option>
                    {/* <option value={1}>Progressive - Across Section</option>
                    <option value={2}>Progressive - Section-wise</option> */}
                  </select>
                </div>
              </div>
            </div>

            <p style={{ paddingTop: '10px', paddingBottom: '10px' }} className={style.info}>
              All questions will follow this marking logic. Marking logic can be overwritten at a question level on next
              screen.
            </p>

            <div className={style.bound}>
              <div className={style.templateDrop}>
                <div className={style.templateDrop_item}>
                  <div className={style.templateGroup}>
                    <span>Correct Answer Marks </span>
                    <b className={style.red_mendantory}>*</b>
                    <input
                      disabled
                      name="question"
                      id="question"
                      className={style.score_drop_filter}
                      placeholder="Input +/- score"
                      value={testDetails.correct_answer_marks}
                    />
                  </div>
                </div>
              </div>

              <div className={style.templateDrop}>
                <div className={style.templateDrop_item}>
                  <div className={style.templateGroup}>
                    <span>Incorrect Answer Marks </span>
                    <b className={style.red_mendantory}>*</b>
                    <input
                      name="question"
                      id="question"
                      className={style.score_drop_filter}
                      placeholder="Input +/- score"
                      disabled
                      value={testDetails.incorrect_answer_marks}
                    />
                  </div>
                </div>
              </div>

              {testDetails?.rendering_template === 5 && (
                <>
                  <div className={style.templateDrop}>
                    <div className={style.templateDrop_item}>
                      <div className={style.templateGroup}>
                        <span>Unattempted Penalty </span>
                        <b className={style.red_mendantory}>*</b>
                        <input
                          type="number"
                          name="question"
                          id="question"
                          className={style.score_drop_filter}
                          disabled
                          value={testDetails.unattempted_penalty}
                          placeholder="Input +/- score"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={style.templateDrop}>
                    <div className={style.templateDrop_item}>
                      <div className={style.templateGroup}>
                        <span>Unattempted Penalty Start From </span>
                        <b className={style.red_mendantory}>*</b>
                        <input
                          type="number"
                          name="question"
                          id="question"
                          className={style.score_drop_filter}
                          placeholder="Input +/- score"
                          value={testDetails?.unattempted_penalty_starts_from}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <hr className={style.hrLine} />

            <h3 className={style.testMarklabel}>Test Instructions</h3>
            <p className={style.info}>Add instructions to appear before the test commences</p>
            {testDetails?.instructions?.map((instruction, index) => (
              <div key={index} className={style.screenNum}>
                <p style={{ fontWeight: 500 }}>Screen {index + 1}</p>
                <div className={style.icon_text}>
                  <input
                    type="checkbox"
                    // checked={testDetails.instructions[0]}
                    checked={instruction.has_timer}
                    disabled
                  />
                  {/* <label>Has Timer</label> */}

                  <p className={style.info}>Instruction Screen has a timer</p>
                </div>
                <div className={style.timer}>
                  <input
                    disabled
                    className={style.timer_input}
                    type="number"
                    name="minutes"
                    min="0"
                    max="100"
                    step="1"
                    value={instruction.minutes}
                  />
                  <label htmlFor="minutes">Minutes</label>

                  <input
                    className={style.timer_input}
                    type="number"
                    name="seconds"
                    min="0"
                    max="100"
                    step="1"
                    disabled
                    value={instruction.seconds}
                  />
                  <label htmlFor="seconds">Seconds</label>
                </div>

                <p className={style.marketLogic}>Test Instructions</p>
                <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                  {' '}
                  {/* <Editor
                    apiKey='43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz'
                    init={{
                      plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker markdown',
                      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    }}
                    disabled
                    initialValue={decodeBase64(instruction.instruction_text)}
                  /> */}
                  <div style={{ height: 'auto', flexGrow: '1' }}>
                    <QuillEditor defaultValue={decodeBase64(instruction.instruction_text)} disabled />
                  </div>
                  {/* {index !== 0 && (
                    <button
                      type="button"
                      className={style.penaltyDelete}
                      onClick={() => removeScreen(index)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.8504 9.14062L18.2004 19.2106C18.0904 20.7806 18.0004 22.0006 15.2104 22.0006H8.79039C6.00039 22.0006 5.91039 20.7806 5.80039 19.2106L5.15039 9.14062"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  )} */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDeatils;
