import React, { useState } from 'react';

// Define the props type
interface MyComponentProps {
  initialCount: number;
  title: string;
}

// Define the component
const TestComponent: React.FC<MyComponentProps> = ({ initialCount, title }) => {
  const [count, setCount] = useState<number>(initialCount);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  return (
    <div>
      <h1>{title}</h1>
      <p>Current Count: {count}</p>
      <button onClick={increment}>Increment</button>
      <button onClick={decrement}>Decrement</button>
    </div>
  );
};

export default TestComponent;
