import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './style.css';
import style from '../../../../assets/styles/signin.module.css';
import { useNavigate } from 'react-router-dom';
import PublicLayout from '../../../../layout/PublicLayout';
import { CallWithOutAuth } from '../../../../action/apiActions';
import Cookies from 'js-cookie';

interface LoginResponse {
  id: number;
  username: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  groups: number[];
  jwt: string;
  jwt_refresh: [string, string];
  token: string;
  is_first_login: boolean;
  status: number;
  message: string;
}

interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [apiError, setApiError] = React.useState<string>('');
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const LOGIN_API_URL = `${BASE_URL}login/`;
  // Enhanced validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email is required')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Please enter a valid email address'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
      .matches(/^(?=.*[A-Za-z])(?=.*\d).+$/, 'Password must be alphanumeric.')
      .required('Password is required'),
  });

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: localStorage.getItem('rememberedEmail') || '',
      password: '',
      rememberMe: Boolean(localStorage.getItem('rememberedEmail')),
    },
    validationSchema,
    //onSubmit, setSubmitting state is added when ever api call happened
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setApiError('');

        const response: any = await CallWithOutAuth('POST', LOGIN_API_URL, {
          username: values.email,
          password: values.password,
        });
        if (response?.status && response?.res?.data?.data) {
          const responseData = response?.res?.data?.data;
          const data: LoginResponse = responseData;

          // Handle tokens

          // Handle remember me
          if (values.rememberMe) {
            localStorage.setItem('rememberedEmail', values.email);
          } else {
            localStorage.removeItem('rememberedEmail');
          }

          // Handle navigation based on first login status
          if (data.is_first_login) {
            //create-password/?token=ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6STFOaUo5LmV5SjFjMlZ5WDJsa0lqb3hOVGt4TENKbGVIQWlPakUzTWprNE5UWTBNek45Lm1MX0pWaEVOT1N2S1lWMnM0YU1aVjh3T3d0NjJiNUdGNkNMQXVOQWdMWVk=/
            navigate(`/create-password/?token=${data.token}/`);
          } else {
            navigate('/question-bank', { replace: true });
            localStorage.setItem('userInfo', JSON.stringify(data));
            Cookies.set('accessToken', data?.jwt);
            sessionStorage.setItem('refreshToken', data?.jwt_refresh[0]);
          }
        } else {
          // Handle different error responses
          const errorData = response?.res?.data;

          if (errorData?.error) {
            setApiError(errorData?.error);
          } else {
            setApiError('Login failed. Please check your credentials.');
          }
        }
      } catch (error) {
        setApiError('An unexpected error occurred. Please try again.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      formik.setFieldValue('email', savedEmail);
      formik.setFieldValue('rememberMe', true);
    }
  }, []);

  return (
    <PublicLayout>
      <form className={style.user_form} onSubmit={formik.handleSubmit}>
        <h2 className={style.text_signin}>Sign In</h2>
        <div className={style.error_wrap} style={{visibility: apiError ? 'visible' : 'hidden'}}>
            <span className={style.warningIcon}></span>
            <div className={style.validation_mailError} >{apiError}</div>
          </div>
        <div className={style.user_info}>
          <div className={style.mail_and_pass}>
            <div className={style.mail_wrap}>
              <label htmlFor="email" style={{ fontWeight: 'bold' }}>
                Email Address
              </label>
              <input
                className={style.mail}
                type="text"
                id="email"
                placeholder="Enter your email address"
                {...formik.getFieldProps('email')}
                disabled={formik.isSubmitting}
              />
              <div
                className={style.validationError}
                style={{ visibility: formik.touched.email && formik.errors.email ? 'visible' : 'hidden' }}
              >
                {formik.errors.email}
              </div>
            </div>

            <div className={style.pass_wrap}>
              <label htmlFor="password" style={{ fontWeight: 'bold' }}>
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter password"
                {...formik.getFieldProps('password')}
                disabled={formik.isSubmitting}
              />
              <div
                className={style.validationError}
                style={{ visibility: formik.touched.password && formik.errors.password ? 'visible' : 'hidden' }}
              >
                {formik.errors.password}
              </div>
            </div>
          </div>

          <div className={style.user_options}>
            <input
              type="checkbox"
              id="rememberMe"
              checked={formik.values.rememberMe}
              onChange={(e) => formik.setFieldValue('rememberMe', e.target.checked)}
              disabled={formik.isSubmitting}
            />
            <label htmlFor="rememberMe" className={style.user_memo}>
              Remember me
            </label>
            <span
              className={style.forget_pass}
              onClick={() => navigate('/forgot-password')}
              style={{ fontWeight: '500', textDecoration: 'underline', cursor: 'pointer' }}
            >
              Forgot Password?
            </span>
          </div>
        </div>

        <div className={style.signin_button}>
          <button
            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            style={{
              cursor: !(formik.isValid && formik.dirty) || formik.isSubmitting ? 'no-drop' : 'pointer',
              background: !(formik.isValid && formik.dirty) || formik.isSubmitting ? 'lightgray' : '#00ABFB',
            }}
            type="submit"
            className={`${style.bt_signup} ${style.dynamic_button}`}
          >
            {formik.isSubmitting ? 'Signing In...' : 'Sign In'}
          </button>
        </div>
      </form>
    </PublicLayout>
  );
};

export default Login;
