import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BasicInformation,
  CreateQuestionAction,
  QuestionCreationScreen,
  QuestionCreationScreenCancel,
} from '../../../../redux/action';
import { useEffect } from 'react';
import { useState } from 'react';
import { CallWithAuth } from '../../../../action/apiActions';
import { POST_INITIALIZING_QUESTION_CREATION } from '../../../../action/apiPath';

import style from '../../../../assets/styles/createQuestion.module.css';

const SendForReview = ({ id }) => {
  const location = useLocation();
  const receivedState = location.state;
  // console.log("receivedState", receivedState)

  // ________ initialization :start ____________
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ________ initialization :end ____________

  // cancel button to send the user to question-bank
  const Cancel = () => {
    navigate('/question-bank');
    dispatch(QuestionCreationScreenCancel());
  };

  // START : initializeQuestion for getting the ID so that backend will know that we are start creating question
  const [ids, setId] = useState(0);

  const SameSettingQuestion = async () => {
    dispatch(QuestionCreationScreenCancel());
    navigate('/create-question-same/' + id);
    // create new ID
    // const initializeID = await CallWithAuth("POST", POST_INITIALIZING_QUESTION_CREATION)
    // if (initializeID.status && typeof initializeID.res.data.id === 'number') {
    //     setId(initializeID.res.data.id)
    //     navigate("/create-question-same/"+id)
    // }
  };

  // _____________ REDUX SELECTOR _____________
  const selector = useSelector((state) => state);
  // console.log("selectorss", selector,ids)
  const [status, setStatus] = useState(0);

  // const [screen,setScreen]=useState(0)
  // useEffect(() => {
  //     if (ids !== 0) {
  //         if (screen === 0) {
  //             dispatch(CreateQuestionAction(selector.CreateQuestionReducer.question_bank_id, id))
  //         } else if (screen === 1) {
  //             dispatch(BasicInformation({
  //                 "format": selector.BasicInformationReducer.format,
  //                 "difficulty": selector.BasicInformationReducer.difficulty,
  //                 "primary_tag_id": selector.BasicInformationReducer.primary_tag_id,
  //                 "secondary_tags": selector.BasicInformationReducer.secondary_tags,
  //                 "free_tags": selector.BasicInformationReducer.free_tags,
  //                 "new_free_tags": selector.BasicInformationReducer.new_free_tags
  //             }, id))
  //         } else if (screen === 2.1) {
  //             dispatch(QuestionCreationScreen({
  //                 "question_type_id": selector?.QuestionScreenReducer?.questiontype?.question_type_id
  //             }))
  //         }
  //     }
  // }, [ids])

  const [editSection, setEditSection] = useState(false);

  useEffect(() => {
    if (receivedState != null) {
      if (Object.entries(receivedState).length > 0) {
        setEditSection(true);
      }
    }
  }, [receivedState]);

  console.log('receivedState', receivedState);

  return (
    <>
      <div className={style.select_question}>
        {/* send for review  */}
        <div className={style.send_for_review}>
          {/* top section */}
          <div className={style.top}>
            <div className={style.icon}>
              <svg width="59" height="57" viewBox="0 0 59 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.2"
                  d="M49.6357 7.55216L33.7844 3.30481C28.0347 1.76419 23.7849 4.23377 22.2392 10.0024L17.0042 29.5399C15.4332 35.4031 17.899 39.591 23.6487 41.1316L39.5 45.379C45.3424 46.9444 49.4773 44.5251 51.0483 38.662L56.2834 19.1245C57.8291 13.3559 55.478 9.11762 49.6357 7.55216Z"
                  fill="url(#paint0_linear_240_32226)"
                />
                <path
                  d="M32.5127 11.3438H12.9803C5.34309 11.3438 0.206543 16.7056 0.206543 24.6808V43.0867C0.206543 51.0394 5.34309 56.4012 12.9803 56.4012H32.5127C40.15 56.4012 45.264 51.0394 45.264 43.0867V24.6808C45.264 16.7056 40.15 11.3438 32.5127 11.3438Z"
                  fill="url(#paint1_linear_240_32226)"
                />
                <path
                  d="M20.0616 41.1905C19.557 41.1905 19.0523 40.999 18.6671 40.6137L13.321 35.2677C12.5505 34.4972 12.5505 33.2491 13.321 32.4809C14.0915 31.7104 15.3373 31.7081 16.1078 32.4786L20.0616 36.4324L29.3615 27.1325C30.132 26.3621 31.3778 26.3621 32.1483 27.1325C32.9188 27.903 32.9188 29.1511 32.1483 29.9216L21.4562 40.6137C21.0709 40.999 20.5663 41.1905 20.0616 41.1905Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_240_32226"
                    x1="26.135"
                    y1="-4.53664"
                    x2="15.034"
                    y2="36.8927"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCF00" />
                    <stop offset="1" stopColor="#FF8800" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_240_32226"
                    x1="0.206543"
                    y1="4.90697"
                    x2="0.206543"
                    y2="54.2556"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCF00" />
                    <stop offset="1" stopColor="#FF8800" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className={style.question_text}>
              <h3>Your question has been successfully {!editSection ? 'sent for Review!' : 'edited'}</h3>
              <p>
                Question ID {id} has been {!editSection ? 'sent for review' : 'updated'}
              </p>
            </div>
          </div>

          {/* bottom section */}
          {!editSection ? (
            <div className={style.create}>
              {/* line  */}
              <div className={style.line_div}>
                <div className={style.line}></div>
              </div>

              {/* button section  */}
              <h4>Do you want to create another question using the same settings?</h4>
              <div className={style.saveButton_questionCreation_Modal}>
                <div className={` ${style.cancel} ${style.pointer}`} onClick={() => Cancel()}>
                  No, Create New
                </div>
                {/* <div>
                                <button className={style.blue_button} onClick={() => SameSettingQuestion()}>  Yes, Use the same</button>
                            </div> */}
              </div>
            </div>
          ) : (
            <div className={style.create}>
              {/* line  */}
              <div className={style.line_div}>
                <div className={style.line}></div>
              </div>

              {/* button section  */}

              <div className={style.saveButton_questionCreation_Modal}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button style={{ width: '30%' }} className={style.blue_button} onClick={() => Cancel()}>
                    {' '}
                    Go Back to Question Listing
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SendForReview;
