import React from 'react';
import SideBarMenu from './SideBarMenu';
import logo from '../../../assets/images/Group.png';

import style from '../../../assets/styles/sidebarStyle.module.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const SideBar = ({ sidebarshow }) => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    Cookies.set('accessToken', null);
    sessionStorage.removeItem('refreshToken');
    navigate('/sign-in',{replace:true});
  };
  return (
    <>
      <div id={style.side_bar} className={sidebarshow ? `${style.hide}` : ''}>
        <div className={style.side_bar_contents}>
          {/* _________  TOM SIDEBAR AREA FOR LOGO _________ */}
          <div className={style.menu_top_area}>
            <div className={style.avatar}>
              <img src={logo} className={style.image_logo} width={62.94} height={44.94} alt="" />
            </div>
          </div>

          {/* _________  SIDEBAR MENU CONTENT  _________ */}
          <SideBarMenu />

          {/* _________  LOGOUT BUTTON AREA  _________ */}
          <div className={style.menu_bottom_area}>
            <div className={style.menu_bottom_area_sub}>
              <ul className={style.main_menus}>
                <li>
                  <div className={style.menu_item_content}>
                    <div className={style.menu_item}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.7">
                          <path
                            d="M8.90039 7.56219C9.21039 3.96219 11.0604 2.49219 15.1104 2.49219H15.2404C19.7104 2.49219 21.5004 4.28219 21.5004 8.75219V15.2722C21.5004 19.7422 19.7104 21.5322 15.2404 21.5322H15.1104C11.0904 21.5322 9.24039 20.0822 8.91039 16.5422"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.9991 12H3.61914"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.85 8.64844L2.5 11.9984L5.85 15.3484"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>

                      <span onClick={() => handleLogOut()} className={style.menu_name}>
                        Logout
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
