import React, { useEffect, useRef, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';
import deleteIcon from '../../../../../../assets/images/deleteCrossBtn.svg';

interface Option {
  id: number;
  value: string;
  isSelected: boolean;
  nonDeletable?: boolean;
}

interface Options {
  [key: string]: Option[];
}

interface GraphAnalysisProps {
  body: any[]; // Replace 'any' with specific type for `body` if known
  questionbutton: number;
  setbodyformultiquestion: (body: any[]) => void;
}

const GraphAnalysis: React.FC<GraphAnalysisProps> = ({ body, questionbutton, setbodyformultiquestion }) => {
  const [questionIdentifier, setQuestionIdentifier] = useState<string | undefined>(body[questionbutton]?.question_short_identifier);
  const [questionDirection, setQuestionDirection] = useState<string | undefined>(body[questionbutton]?.question_direction);
  const [compose_text, setCompose_text] = useState<string | undefined>(body[questionbutton]?.compose_question);
  const [explanation_text, setExplanation_text] = useState<string | undefined>(body[questionbutton]?.explaination_text);
  const [videoId, setvideoId] = useState<string | undefined>(body[questionbutton]?.video_id);
  const [options, setOptions] = useState<Options>({});
  const editorRef = useRef<any>(null);
  // Handle option selection (correct answer)
  const handleOptionSelect = (dropdown: string, id: number) => {
    setOptions({
      ...options,
      [dropdown]: options[dropdown].map((option) =>
        option.id === id ? { ...option, isSelected: true } : { ...option, isSelected: false }
      ),
    });
  };

  // Handle option value change
  const handleOptionChange = (dropdown: string, id: number, value: string) => {
    setOptions({
      ...options,
      [dropdown]: options[dropdown].map((option) => (option.id === id ? { ...option, value } : option)),
    });
  };

  // Handle adding a new option to a dropdown
  const addOption = (dropdown: string) => {
    setOptions({
      ...options,
      [dropdown]: [...options[dropdown], { id: options[dropdown].length + 1, value: '', isSelected: false }],
    });
  };

  const updateOptionsOnButtonRemove = (data: string | number) => {
    const updatedOptions = options;
    delete updatedOptions[data];
    setOptions(updatedOptions);
    editorRef.current.execCommand(
      'mceInsertContent',
      false,
     ""
    );
    
   
  };

  // Handle removing an option (except first two)
  const removeOption = (dropdown: string, id: number) => {
    setOptions({
      ...options,
      [dropdown]: options[dropdown].filter((option) => option.id !== id || option.nonDeletable),
    });
  };


  const mapOptions = (options: any[]) => {
    return options.map(option => ({ [option.value]: option.isSelected }));
  }

  const convertJsonPayloadFormat = (options: Options) => {
    let requiredJson: any = [{}];
    for (const optionKey in options) {
      if (Object.prototype.hasOwnProperty.call(options, optionKey)) {
        const element = options[optionKey];
        let key = optionKey.toLowerCase() + '_option'
        requiredJson[0][key] = [mapOptions(element)]
      }
    }
    return requiredJson
  }

  const transformOptionArray = (responseOptions: any[]) => {
    let options = responseOptions[0]
    let newOptionArr: any = {}
    for (const optionKey in options) {
      if (Object.prototype.hasOwnProperty.call(options, optionKey)) {
        const element = options[optionKey];
        let responseArr = createOptionArray(element)
        let key = optionKey.split("_")[0]?.toUpperCase()
        newOptionArr[key] = responseArr
      }
    }
    return newOptionArr
  }

  const createOptionArray = (optionArray: any[][], nonDeletableCount = 2) => {
    let id = 1;
    let targetArray: {
      id: number; value: string; nonDeletable: boolean; // First two options are non-deletable
      isSelected: unknown;
    }[] = []
    optionArray[0].forEach(optionObj => {
      const [key, isSelected] = Object.entries(optionObj)[0];
      targetArray.push({
        id: id++,
        value: key,
        nonDeletable: id <= nonDeletableCount + 1, // First two options are non-deletable
        isSelected: isSelected
      });
    });
    return targetArray
  }

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionIdentifier,
      question_direction: questionDirection,
      compose_question: compose_text,
      response_options: convertJsonPayloadFormat(options),
      video_id: videoId || null,
      explaination_text: explanation_text || null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    if (body[questionbutton]?.response_options) {
      const transformOptions = transformOptionArray(body[questionbutton]?.response_options)
      setOptions(transformOptions)
    } else {
      setOptions({})
    }
  }, [])

  useEffect(() => {
    AddUpdateBody();
  }, [questionIdentifier, questionDirection, compose_text, options, videoId, explanation_text]);

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  //css object for the dynamic dropdown button 
  const buttonStyles = {
    dropdownButton: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #00ABFB',
      borderRadius: '10px',
      background: 'white',
      width: '143px',
      height: '40px',
      gap: '25px',
      padding: '0 10px',
      cursor: 'pointer',
      margin: '0 5px'
    },
    buttonText: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '18px',
      color: '#00ABFB'
    },
    deleteBtn: {
      width: '20px',
      height: '20px',
      backgroundImage: `url(${deleteIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      cursor: 'pointer'
    }
  };
  const setRef =(data:any)=>{
    editorRef.current = data;
    debugger
  }
  const getStyleString = (styleObj: { [s: string]: unknown; } | ArrayLike<unknown>) => {
    return Object.entries(styleObj)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
      .join('; ');
  };
  //Function to insert button into TinyMCE editor
  const addResponseBtnClickHandler = () => {
    const newOption = [
      { id: 1, value: '', nonDeletable: true, isSelected: false },
      { id: 2, value: '', nonDeletable: true, isSelected: false },
      { id: 3, value: '', isSelected: false },
    ];
    const optionKeyArr = Object.keys(options);
    let newSelectOptionskey = '';
    if (optionKeyArr?.length > 0) {
      newSelectOptionskey = optionKeyArr[optionKeyArr.length - 1].replace(/[^0-9]/g, '');
      newSelectOptionskey = `R${Number(newSelectOptionskey) + 1}`;
    } else {
      newSelectOptionskey = 'R1';
    }
   

    let updatedOptions = options;
    updatedOptions[newSelectOptionskey] = newOption;
    setOptions(updatedOptions);

 
    const buttonHTML = `
      <button class="dynamic-button mceNonEditable" id="${newSelectOptionskey}" style="${getStyleString(buttonStyles.dropdownButton)}">
        <span class='button-getter' style="${getStyleString(buttonStyles.buttonText)}">  ${newSelectOptionskey} Dropdown </span>
        <span class="delete-btn" style="${getStyleString(buttonStyles.deleteBtn)}" >
        </span> </button> `;

        editorRef.current.execCommand(
          'mceInsertContent',
          false,
          buttonHTML
        );
    // let text = '';
    // if (compose_text) {
    //   text = b64DecodeUnicode(compose_text);
    // }
    // // Regex to capture the content inside <p>...</p>
    // const regex1 = /<p>(.*?)<\/p>/;

    // // Execute the regex
    // const match = text.match(regex1);

    // // Extract the content if a match is found
    // const pContent = match ? match[1] : null;

    // // Replace the matched groups
    // let outputText = '';
    // if (compose_text) {
    //   outputText = `<p>${pContent} ${buttonHTML} </p>`;
    // } else {
    //   outputText = `<p>${buttonHTML} </p>`;
    // }

    // setCompose_text(btoa(outputText));
    // }
  };

  return (
    <div className={style.tinymce_text} style={{ marginTop: '15px' }}>
      {/* add Question Short Identifier with tnymce*/}
      <div className={style.Question_Direction}>
        <p>
          Question Short Identifier <b className={style.red_mendantory}>*</b>
        </p>
        <textarea
          placeholder="Enter Question Short Identifier"
          className={`${style.question_identifier} ${style.Shortquestion}`}
          value={questionIdentifier}
          onChange={(e) => setQuestionIdentifier(e.target.value)}
        />
      </div>

      {/* add Question Direction with tnymce*/}
      <div className={`${style.Question_Direction} ${style.QuestionDirection}`}>
        <p>
          Enter Question Direction
        </p>
        <textarea
          placeholder="Type Question Directions"
          className={style.question_identifier}
          value={questionDirection}
          onChange={(e) => setQuestionDirection(e.target.value)}
        />
      </div>

      {/* add compose quetion*/}
      <div className={`${style.explanation_text} ${style.explanationtext}`}>
        <p>
          Compose question <b className={style.red_mendantory}>*</b>
          {/* <br></br> */}
          {/* <span style={{ fontWeight: 'normal', position: 'relative', top: '6px' }}>
            Click on the "Add Dropdown" button whenever needed
          </span> */}
        </p>
        <div className={style.ComposeQuestion} >
          <QuillEditor
          refSetter={(data:any)=>setRef(data)}
            ref={editorRef}
            toolbarList={false}
            defaultValue={compose_text != null ? b64DecodeUnicode(compose_text) : ''}
            onChange={(text) => setCompose_text(btoa(text))}
            onRemove={updateOptionsOnButtonRemove}
            data={options}
          />
          {/* add respose button*/}
          <button onClick={addResponseBtnClickHandler} className={style.addResponsebtn}>
            <span className={style.addIcon}></span>
            <span className={style.addResponsText}>Add Response</span>
          </button>
        </div>
      </div>

      {/* add dropdown options*/}
      <div className={`${style.compose_text} ${style.composeText}`}>
        <div className={style.optionsGrid}>
          {Object.entries(options).map(([section, sectionOptions]) => (
            <div key={section} className={style.optionSection}>
              <h4 className={style.sectionTitle}>{section.toUpperCase()} Options</h4>
              <div className={style.optionsList}>
                {sectionOptions.length > 0 &&
                  sectionOptions?.map((option, index) => (
                    <div key={option.id} className={style.optionItem}>
                      <div className={style.inputWrapper}>
                        <input
                          type="radio"
                          name={`${section}-option`}
                          checked={option.isSelected}
                          onChange={() => handleOptionSelect(section, option.id)}
                          className={style.radioInput}
                        />
                        <label
                          className={style.optionLabel}
                        >{`${section.toUpperCase()} - ${String.fromCharCode(65 + index)}`}</label>
                        <input
                          type="text"
                          value={option.value}
                          placeholder="Enter Option"
                          onChange={(e) => handleOptionChange(section, option.id, e.target.value)}
                          className={style.responseInput}
                        />
                        {!option.nonDeletable && (
                          <button
                            onClick={() => removeOption(section, option.id)}
                            className={style.deleteButton}
                          ></button>
                        )}
                      </div>
                    </div>
                  ))}
                <button onClick={() => addOption(section)} className={style.addButton}>
                  <span className={style.addPlusButton}></span>
                  Add Option
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* </div> */}
      </div>
      <span className={style.seprator}></span>
      {/* add Video Id */}
      <div className={`${style.explanation_text} ${style.videoId}`}>
        <p>
          Video Id <b className={style.red_mendantory}>*</b>
        </p>
        <div className={style.explanation_video}>
          <input
            type="text"
            value={videoId}
            placeholder="Video Cipher ID"
            onChange={(e) => setvideoId(e.target.value)}
          />
        </div>
      </div>

      {/* add explanation_text with tnymce*/}
      <div className={style.explanation_text}>
        <p>
          Add Explanation Text <b className={style.red_mendantory}>*</b>
        </p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            toolbarList={false}
            defaultValue={explanation_text != null ? b64DecodeUnicode(explanation_text) : ""}
            onChange={(text) => setExplanation_text(btoa(text))}
          />
        </div>
      </div>
    </div>
  );
};

export default GraphAnalysis;