import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import * as Sentry from '@sentry/react';
import { withToastProvider } from './component/molecule/common/Toast';

Sentry.init({
  dsn: 'https://b5e62d6d8bff6eb65c034f04886ac79a@sentry.tools.imsindia.com/2',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});
const AppWithToastProvider = withToastProvider(App);
const WithProfiler = Sentry.withProfiler(AppWithToastProvider);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Sentry.ErrorBoundary>
        <WithProfiler />
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
