import { takeEvery, put, call } from 'redux-saga/effects';
import {
  BASIC_INFO,
  BASIC_INFORMATION,
  GET_TASKS_LIST,
  GET_IRT_REPONSE,
  GET_IRT_REPONSE_FAIL,
  GET_IRT_REPONSE_SUCCESS,
  GET_QDR_REPONSE_FAIL,
  GET_QDR_REPONSE_SUCCESS,
  GET_REPORT,
  GET_REPORT_IRT,
  GET_REPORT_QDR,
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  QUESTION_BANK,
  QUESTION_CREATION,
  QUESTION_CREATION_CANCEL,
  QUESTION_CREATION_CHOOSE_BACK,
  QUESTION_CREATION_FINAL_CALL,
  QUESTION_CREATION_ONE,
  QUESTION_CREATION_SCREEN_BACK,
  QUESTION_CREATION_SCREEN_CANCEL,
  QUESTION_CREATION_SCREEN_CHANGE,
  QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK,
  QUESTION_CREATION_TWO,
  SELECT_QUESTION_BANK,
  SELECT_QUESTION_BANK_RESPONSE,
  SEND_FOR_REVIEW,
  GET_TASKS_DETAIL,
  GET_TASKS_DETAIL_SUCCESS,
  GET_TASKS_DETAIL_FAIL,
  DELETE_TASKS,
  APPROVE_TASKS,
  DELETE_TASKS_SUCCESS,
  DELETE_TASKS_FAIL,
  APPROVE_TASKS_SUCCESS,
  APPROVE_TASKS_FAIL,
  REVIEW_ALLDATA_SUCCESS,
  REVIEW_ALLDATA_FAIL,
  GET_REVIEW_ALL_DATA,
  GET_NEED_REVIEW_ALL_DATA,
  NEED_REVIEW_ALLDATA_SUCCESS,
  NEED_REVIEW_ALLDATA_FAIL,
  CHANGE_REVIEW_STATUS,
  CHANGE_REVIEW_STATUS_FAIL,
  CHANGE_REVIEW_STATUS_SUCCESS,
  GET_USER_LIST,
  GET_USER_LIST_SUCESS,
  GET_USER_LIST_FAIL,
  GET_USER_DETAIL,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_ROLES,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAIL,
  GET_USER_QUESTION_BANK,
  GET_USER_QUESTION_BANK_SUCCESS,
  GET_USER_QUESTION_BANK_FAIL,
  GET_ADD_USER,
  GET_ADD_USER_SUCCESS,
  GET_ADD_USER_FAIL,
  EDIT_USER_DETAIL,
  EDIT_USER_DETAIL_SUCCESS,
  EDIT_USER_DETAIL_FAIL,
  GET_ORGANISATION_LIST,
  GET_ORGANISATION_LIST_SUCCESS,
  GET_ORGANISATION_LIST_FAIL,
  GET_ORGANISATION_DETAIL,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAIL,
  GET_IDENTIFIER_LIST,
  GET_LOD_LIST,
  GET_SUBJECT_PIPELINE_LIST,
  GET_IDENTIFIER_LIST_SUCCESS,
  GET_IDENTIFIER_LIST_FAIL,
  GET_LOD_LIST_SUCCESS,
  GET_LOD_LIST_FAIL,
  GET_SUBJECT_PIPELINE_LIST_SUCCESS,
  GET_SUBJECT_PIPELINE_LIST_FAIL,
  GET_QUESTION_BANK_LIST,
  GET_QUESTION_BANK_LIST_SUCCESS,
  GET_QUESTION_BANK_LIST_FAIL,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAIL,
  GET_PRODUCT_TYPE,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_FAIL,
  EDIT_TASK,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAIL,
  CREATE_TEST,
  CREATE_TEST_SUCCESS,
  CREATE_TEST_FAIL,
  GET_TASK_OF_USER,
  CREATE_TEST_BASIC_SETTING,
  CREATE_TEST_BASIC_SETTING_SUCCESS,
  CREATE_TEST_BASIC_SETTING_FAIL,
  CHANGE_ADD_TEST_SCREEN,
  CREATE_TEST_SECTION,
  PUBLISH_TEST,
  GET_TEST_LISTING_SUCCESS,
  GET_TEST_LISTING_FAIL,
  GET_TEST_LISTING,
  CHANGE_TEST_SCREEN,
  DELETE_TEST_SUCCESS,
  DELETE_TEST_FAIL,
  DELETE_TEST,
  UNPUBLISH_TEST_FAIL,
  UNPUBLISH_TEST_SUCCESS,
  UNPUBLISH_TEST,
  DUPLICATE_TEST_SUCCESS,
  DUPLICATE_TEST_FAIL,
  DUPLICATE_TEST,
  PRODUCT_TYPE,
  PRODUCT_TYPE_SUCCUSS,
  PRODUCT_TYPE_FAIL,
  GET_TEST_DETAILS,
  GET_TEST_DETAILS_SUCCESS,
  GET_TEST_DETAILS_FAIL,
  CREATE_TEST_SECTION_SUCCESS,
  CREATE_TEST_SECTION_FAIL,
  PUBLISH_TEST_SUCCESS,
  PUBLISH_TEST_FAIL,
  GET_REPLACE_QUESTION,
  GET_REPLACE_QUESTION_SUCCESS,
  GET_REPLACE_QUESTION_FAIL,
  PUBLISHED_TEST,
  PUBLISHED_TEST_SUCCESS,
  PUBLISHED_TEST_FAIL,
  GET_TETS_DETAILS_FOR_EDIT_SUCCESS,
  GET_TETS_DETAILS_FOR_EDIT_FAIL,
  GET_TETS_DETAILS_FOR_EDIT,
  UPDATE_BASIC_SETTINGS_SUCCESS,
  UPDATE_BASIC_SETTINGS_FAIL,
  UPDATE_BASIC_SETTINGS,
  EDIT_CREATE_TEST_SECTION,
  EDIT_CREATE_TEST_SECTION_SUCCESS,
  EDIT_CREATE_TEST_SECTION_FAIL,
} from './constant';
import {
  CREATE_TASKS,
  CREATE_TESTS,
  CREATE_TESTS_BASIC_SETTING,
  CREATE_TESTS_SECTION,
  CREATE_USERS,
  EDIT_REVIEW_STATUS,
  GET_CATEGORY,
  GET_IDENTIFIER,
  GET_LOD,
  GET_ORGANISATION,
  GET_PRODUCT,
  GET_PRODUCT_TYPE_LIST,
  GET_QUESTIONBANK,
  GET_QUESTION_BANK,
  GET_REPLACE_THIS_QUESTION,
  GET_REPORT_DATA,
  GET_REVIEW_DATA,
  GET_ROLES,
  GET_SUBJECT_TAG,
  GET_TASKS,
  GET_TASKS_OF_USER,
  GET_TEST_LISTS,
  GET_USERS,
  PATCH_SELECT_QUESTION,
} from '../action/apiPath';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import {
  Change_qdr_status,
  change_test_screen,
  changeAdd_test_screen,
  changeEdit_test_screen,
  ChangeOrganisation,
  changeOrganisation,
  Get_Review,
  Get_Task_Management,
  Get_Test_list,
  GetUserLists,
} from './action';

// ______________ START : function of CreateQuestionSaga with data to put in reducer ______________
function* selectQuestionBank({ data, id }) {
  // console.log("data", data);
  if (data !== null) {
    try {
      const url = `${PATCH_SELECT_QUESTION}${id}/select-question-bank/`;
      const body = {
        question_bank_id: data,
      };
      const config = {
        headers: {
          Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
        },
      };
      // console.log("response", url);
      let response = yield call(axios.patch, url, body, config);
      // console.log("response", response);
      if (response.status === 200) {
        yield put({ type: BASIC_INFO, response, screen: 1 });
      } else {
        yield put({ type: BASIC_INFO, response, screen: 0 });
      }
    } catch (err) {
      const keys = Object.keys(err.response.data);
      if (keys.length > 0) {
        const firstErrorKey = keys[0];
        alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
      }
    }
  } else {
    alert('Select any of the question Bank');
  }
}

function* basicInfoSelect({ data, id, format }) {
  const url = `${PATCH_SELECT_QUESTION}${id}/select-basic-info/`;
  const body = data;
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };
  try {
    let response = yield call(axios.patch, url, body, config);
    // console.log("response", response);
    if (response.status === 200) {
      if (format == 1) {
        yield put({ type: QUESTION_CREATION_TWO, response, screen: 2.2 });
      } else {
        yield put({ type: QUESTION_CREATION_ONE, response, screen: 2.1 });
      }
    } else {
      yield put({ type: QUESTION_CREATION_ONE, response, screen: 1 });
    }
  } catch (err) {
    // alert("Input values may be wrong, Please check and try again");
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* questionTypeSelection({ data }) {
  yield put({ type: QUESTION_CREATION_TWO, data, screen: 2.2 });
}

function* questionTypeback() {
  yield put({ type: QUESTION_CREATION_ONE, screen: 2.1 });
}

function* questionTypecancel() {
  yield put({ type: QUESTION_CREATION_CANCEL, screen: 0 });
}

function* questionTypechooseOnlyBack({ data }) {
  yield put({ type: QUESTION_CREATION_CHOOSE_BACK, screen: data });
}

function* question_creation_apicall({ data, id }) {
  // console.log("data", data);
  const url = `${PATCH_SELECT_QUESTION}${id}/create-question/`;
  const body = data;
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  try {
    let response = yield call(axios.patch, url, body, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: SEND_FOR_REVIEW, response, screen: 3 });
    } else {
      yield put({ type: QUESTION_CREATION_TWO, response, screen: 2.2 });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}
// ______________ END : function of CreateQuestionSaga with data to put in reducer ______________

function* get_report_IRT_apicall() {
  const url = `${GET_REPORT_DATA}?report_type=irt`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: GET_IRT_REPONSE_SUCCESS, response });
    } else {
      yield put({ type: GET_IRT_REPONSE_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_report_QDR_apicall(action) {
  const url = `${GET_REPORT_DATA}?report_type=qdr&subject_tags_ids=${action.subjectId}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: GET_QDR_REPONSE_SUCCESS, response });
      yield put(Change_qdr_status(false));
    } else {
      yield put({ type: GET_QDR_REPONSE_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_task_apicall(action) {
  const url = `${GET_TASKS}?keyword=${action.key}&sort=${action.sort}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: GET_TASKS_SUCCESS, response });
    } else {
      yield put({ type: GET_TASKS_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_task_detail_apicall({ id }) {
  const url = `${GET_TASKS}${id}/get_task_details/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: GET_TASKS_DETAIL_SUCCESS, response });
    } else {
      yield put({ type: GET_TASKS_DETAIL_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* delete_task_apicall({ id }) {
  const url = `${GET_TASKS}${id}/delete_task/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.delete, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: DELETE_TASKS_SUCCESS, response });
      yield put(Get_Task_Management());
    } else {
      yield put({ type: DELETE_TASKS_FAIL, response });
      yield put(Get_Task_Management());
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* approve_task_apicall({ id }) {
  const url = `${GET_TASKS}${id}/approve/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };
  const body = {};

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.patch, url, body, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: APPROVE_TASKS_SUCCESS, response });
      yield put(Get_Task_Management());
    } else {
      yield put({ type: APPROVE_TASKS_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* editTask_apicall({ id, body }) {
  const url = `${GET_TASKS}${id}/edit_task/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("MY BODY", body);

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.patch, url, body, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: EDIT_TASK_SUCCESS, response });
      yield put(Get_Task_Management());
    } else {
      yield put({ type: EDIT_TASK_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getTaskofUser_apicall(action) {
  const url = `${GET_TASKS_OF_USER}?task_type=${action.task_type != undefined ? action.task_type : ''}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga11", url, action);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga11", response);
    // if (response.status === 200) {
    //   yield put({ type: GET_TASKS_SUCCESS, response });
    // } else {
    //   yield put({ type: GET_TASKS_FAIL, response });
    // }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_review_alldata_apicall(action) {
  const author = action.author?.map((user) => user.id).join(',');
  const subject = action.subject?.map((user) => user.id).join(',');
  const free = action.free_tags?.map((user) => user.id).join(',');
  const sort = action.sort;

  const url = `${GET_REVIEW_DATA}?subject_tags=${
    subject ? subject : ''
  }&question_authors=${author ? author : ''}&free_tags=${free ? free : ''}&sort=${sort != null ? sort : ''}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: REVIEW_ALLDATA_SUCCESS, response });
    } else {
      yield put({ type: REVIEW_ALLDATA_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_review_needdata_apicall(action) {
  const author = action.author.map((user) => user.id).join(',');
  const subject = action.subject.map((user) => user.id).join(',');
  const free = action.free_tags.map((user) => user.id).join(',');
  const sort = action.sort;

  const url = `${GET_REVIEW_DATA}?in_review=True&subject_tags=${
    subject ? subject : ''
  }&question_authors=${author ? author : ''}&free_tags=${free ? free : ''}&sort=${sort != null ? sort : ''}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: NEED_REVIEW_ALLDATA_SUCCESS, response });
    } else {
      yield put({ type: NEED_REVIEW_ALLDATA_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* change_review_status_apicall(action) {
  const newValue = action.event.target.value;
  const url = `${EDIT_REVIEW_STATUS}${action.id}/update-question-status`;
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };
  const body = { status: newValue };

  try {
    let response = yield call(axios.patch, url, body, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: CHANGE_REVIEW_STATUS_SUCCESS, response });
      yield put(Get_Review(null, null, null));
    } else {
      yield put({ type: CHANGE_REVIEW_STATUS_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_userList_apicall(action) {
  try {
    const keyword = action.keyword;
    const sort = action.sort;
    const url = `${GET_USERS}?keyword=${keyword != null ? keyword : ''}&sort=${sort != null ? sort : ''}`;

    const config = {
      headers: {
        Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
      },
    };
    const response = yield call(axios.get, url, config);
    if (response.status === 200) {
      yield put({ type: GET_USER_LIST_SUCESS, response });
    } else {
      yield put({ type: GET_USER_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_userDetail_apicall(action) {
  const url = `${GET_USERS}${action.id}/user-details/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: GET_USER_DETAIL_SUCCESS, response });
    } else {
      yield put({ type: GET_USER_DETAIL_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_userRole_apicall() {
  const url = `${GET_ROLES}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);
    if (response.status === 200) {
      yield put({ type: GET_USER_ROLES_SUCCESS, response });
    } else {
      yield put({ type: GET_USER_ROLES_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_AddUser_apicall(action) {
  const url = `${CREATE_USERS}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.post, url, action.body, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_ADD_USER_SUCCESS, response });
      yield put(GetUserLists(null, null, null));
    } else {
      yield put({ type: GET_ADD_USER_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

function* editUserDetail_apicall(action) {
  const url = `${GET_USERS}${action.id}/update-user/`;
  // console.log("Body is ", action.body);
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.patch, url, action.body, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: EDIT_USER_DETAIL_SUCCESS, response });
    } else {
      yield put({ type: EDIT_USER_DETAIL_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

function* getOraganisationList_apicall() {
  const url = `${GET_ORGANISATION}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_ORGANISATION_LIST_SUCCESS, response });
    } else {
      yield put({ type: GET_ORGANISATION_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getOraganisationDetail_apicall(action) {
  yield put(ChangeOrganisation(1));
  // const url = `${GET_ORGANISATION}${action.id}/user-details/`;

  // const config = {
  //   headers: {
  //     Authorization: `jwt ${Cookies.get("accessToken")}`, // Include the auth token in the header
  //   },
  // };

  // // console.log("finalsaga", url);

  // try {
  //   let response = yield call(axios.get, url, config);
  //   // console.log("finalsaga", response);

  //   if (response.status === 200) {
  //     yield put({ type: GET_ORGANISATION_LIST_SUCCESS, response });
  //   } else {
  //     yield put({ type: GET_ORGANISATION_LIST_FAIL, response });
  //   }
  // } catch (err) {
  //   alert("Input values may be wrong, Please check and try again");
  // }
}

function* get_userQuestionBank_apicall() {
  const url = `${GET_QUESTIONBANK}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_USER_QUESTION_BANK_SUCCESS, response });
    } else {
      yield put({ type: GET_USER_QUESTION_BANK_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getCategoryListList_apicall(action) {
  const url = `${GET_CATEGORY}?keyword=${action.key}&sort=${action.sort}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_CATEGORY_LIST_SUCCESS, response });
    } else {
      yield put({ type: GET_CATEGORY_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getIdentifierListList_apicall(action) {
  const url = `${GET_IDENTIFIER}?name=${action.key}&sort=${action.sort}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_IDENTIFIER_LIST_SUCCESS, response });
    } else {
      yield put({ type: GET_IDENTIFIER_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getLodListList_apicall(action) {
  const url = `${GET_LOD}?keyword=${action.key}&sort=${action.sort}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_LOD_LIST_SUCCESS, response });
    } else {
      yield put({ type: GET_LOD_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getSubjectPipelineListList_apicall(action) {
  const url = `${GET_SUBJECT_TAG}?keyword=${action.key}&sort=${action.sort}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_SUBJECT_PIPELINE_LIST_SUCCESS, response });
    } else {
      yield put({ type: GET_SUBJECT_PIPELINE_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* getQuestionBankListList_apicall(action) {
  const url = `${GET_QUESTIONBANK}?keyword=${action.key}&sort=${action.sort}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_QUESTION_BANK_LIST_SUCCESS, response });
    } else {
      yield put({ type: GET_QUESTION_BANK_LIST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

/** CREATE NEW TASK */
function* createTask_apicall(action) {
  const url = `${CREATE_TASKS}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.post, url, action.body, config);
    // console.log("finalsaga", response);
    // console.log("My body is", action.body);

    if (response.status === 200) {
      yield put({ type: CREATE_TASK_SUCCESS, response });
    } else {
      yield put({ type: CREATE_TASK_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

/** PRODUCT TYPE IN MASTERS */
function* getProductType_apicall() {
  const url = `${GET_PRODUCT_TYPE_LIST}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_PRODUCT_TYPE_SUCCESS, response });
    } else {
      yield put({ type: GET_PRODUCT_TYPE_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

//___________________________ Test Creation API _________________
function* getNewTest_basic_setting_apicall(action) {
  const url = `${CREATE_TESTS_BASIC_SETTING}`;
  const body = action.body;
  const screen = 1;
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  try {
    let response = yield call(axios.post, url, body, config);
    // console.log("finalsaga22", response);

    if (response.status == 201) {
      yield put({ type: CREATE_TEST_BASIC_SETTING_SUCCESS, response, screen, body });
    } else {
      yield put({ type: CHANGE_ADD_TEST_SCREEN, screen });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

function* getNewTest_Section_apicall(action) {
  const screen = 2;
  const body = action.body;
  // yield put({ type: CHANGE_ADD_TEST_SCREEN, screen });

  const url = `${CREATE_TESTS_SECTION}${action.testCreationId}/add_groups/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  try {
    let response = yield call(axios.patch, url, action.body, config);
    // console.log("finalsaga25", response);

    if (response.status === 200) {
      yield put({ type: CREATE_TEST_SECTION_SUCCESS, screen, body });
    } else {
      yield put({ type: CREATE_TEST_SECTION_FAIL, screen, body });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

function* getNewTestPublish(action) {
  const screen = 0;

  const url = `${GET_TEST_LISTS}${action.testCreationId}/advanced_settings/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };
  // console.log("url", url, config);
  try {
    let response = yield call(axios.patch, url, action.body, config);
    // console.log("finalsaga21", response);

    if (response.status === 200) {
      yield put({ type: PUBLISH_TEST_SUCCESS, screen });
      yield put({ type: CHANGE_TEST_SCREEN, screen });
      yield put(Get_Test_list('', ''));
    } else {
      yield put({ type: PUBLISH_TEST_FAIL, screen });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

function* getTestCreationLists_apicall(action) {
  // console.log("action--", action);

  const { sort, filterParams } = action;

  // Start with the base URL
  let url = `${GET_TEST_LISTS}?sort=${sort}`;

  // Check if filterParams is not empty
  if (Object.keys(filterParams).length > 0) {
    url += `&player_types=${filterParams.player_tags}&free_tags=${filterParams.free_tags}&ids=${filterParams.ids}&authors=${filterParams.author_tags}&year=${filterParams.year}&status=${filterParams.status}`;
  }
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_TEST_LISTING_SUCCESS, response });
    } else {
      yield put({ type: GET_TEST_LISTING_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* delete_test_apicall({ id }) {
  const url = `${GET_TEST_LISTS}${id}/test_delete/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.delete, url, config);
    // console.log("finalsaga", response);
    if (response.status === 204) {
      yield put({ type: DELETE_TEST_SUCCESS, response });
      yield put(Get_Test_list('', ''));
    } else {
      yield put({ type: DELETE_TEST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

//___________________________________________ unpublish test ____________________//
function* unpublish_test_apicall({ id }) {
  const url = `${GET_TEST_LISTS}publish_unpublish/`;

  // const screen= 1
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  const body = {
    id: id,
    publish: false,
  };
  // console.log("Unpublished body", body);

  try {
    let response = yield call(axios.post, url, body, config);
    // console.log("finalsaga22", response);

    if (response.status === 200) {
      yield put({ type: UNPUBLISH_TEST_SUCCESS, response });
      yield put(Get_Test_list('', ''));
    } else {
      yield put({ type: UNPUBLISH_TEST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* publish_test_apicall({ id }) {
  const url = `${GET_TEST_LISTS}publish_unpublish/`;

  // const screen= 1
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  const body = {
    id: id,
    publish: true,
  };
  // console.log("published body", body);

  try {
    let response = yield call(axios.post, url, body, config);
    // console.log("finalsaga22", response);

    if (response.status === 200) {
      yield put({ type: PUBLISHED_TEST_SUCCESS, response });
      yield put(Get_Test_list('', ''));
    } else {
      yield put({ type: PUBLISHED_TEST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

//___________________________________________ duplicate test ____________________//
function* duplicate_test_apicall({ id }) {
  const url = `${GET_TEST_LISTS}duplicate/`;

  // const screen= 1
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  const body = {
    id: id,
  };

  try {
    let response = yield call(axios.post, url, body, config);
    // console.log("finalsaga22", response);

    if (response.status === 201) {
      yield put({ type: DUPLICATE_TEST_SUCCESS, response });
      yield put(Get_Test_list('', ''));
    } else {
      yield put({ type: DUPLICATE_TEST_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

// product type
function* getProductsType_apicall() {
  const url = `${GET_PRODUCT}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: PRODUCT_TYPE_SUCCUSS, response });
    } else {
      yield put({ type: PRODUCT_TYPE_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

// ______ Test Details ___
function* get_TestDetails_apicall({ id }) {
  const url = `${GET_TEST_LISTS}${id}/test_details/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_TEST_DETAILS_SUCCESS, response });
      yield put(change_test_screen(2));
    } else {
      yield put({ type: GET_TEST_DETAILS_FAIL, response });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

// ______ Test Details paticular id to edit___

function* get_TestDetails_for_edit_apicall({ id }) {
  const url = `${GET_TEST_LISTS}${id}/test_details/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // console.log("finalsaga", url);

  try {
    let response = yield call(axios.get, url, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_TETS_DETAILS_FOR_EDIT_SUCCESS, response, id });
      yield put(change_test_screen(3));
    } else {
      yield put({ type: GET_TETS_DETAILS_FOR_EDIT_FAIL, response, id });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

function* get_Replace_apicall(action) {
  const body = {
    question_id: action.id,
    ids: action.otherId,
  };
  // console.log("action", body, action);
  const url = `${GET_REPLACE_THIS_QUESTION}`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  // // console.log("finalsaga", url);

  try {
    let response = yield call(axios.post, url, body, config);
    // console.log("finalsaga", response);

    if (response.status === 200) {
      yield put({ type: GET_REPLACE_QUESTION_SUCCESS, response });
      // yield put(change_test_screen(2))
    } else {
      yield put({ type: GET_REPLACE_QUESTION_FAIL, response });
    }
  } catch (err) {
    // alert("Input values may be wrong, Please check and try again");
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey]}`);
    }
  }
}

//__________________ Edit test basic settings
function* editTest_basic_setting_apicall(action) {
  const url = `${CREATE_TESTS_BASIC_SETTING}${action.id}/update/`;

  const screen = 1;
  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  try {
    let response = yield call(axios.patch, url, action.body, config);
    // console.log("finalsaga22", response);

    if (response.status == 200) {
      yield put({ type: UPDATE_BASIC_SETTINGS_SUCCESS, response, screen });
      yield put(changeEdit_test_screen(1));
    } else {
      yield put({ type: UPDATE_BASIC_SETTINGS_FAIL, response, screen });
      yield put(changeEdit_test_screen(1));
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

function* geteditTest_Section_apicall(action) {
  const screen = 2;
  const body = action.body;
  // yield put({ type: CHANGE_ADD_TEST_SCREEN, screen });

  const url = `${CREATE_TESTS_SECTION}${action.testCreationId}/add_groups/`;

  const config = {
    headers: {
      Authorization: `jwt ${Cookies.get('accessToken')}`, // Include the auth token in the header
    },
  };

  try {
    let response = yield call(axios.patch, url, action.body, config);
    // console.log("finalsaga25", response);

    if (response.status === 200) {
      yield put({ type: EDIT_CREATE_TEST_SECTION_SUCCESS, screen, body });
    } else {
      yield put({ type: EDIT_CREATE_TEST_SECTION_FAIL, screen, body });
    }
  } catch (err) {
    const keys = Object.keys(err.response.data);
    if (keys.length > 0) {
      const firstErrorKey = keys[0];
      alert(`${keys[0].toUpperCase()} : ${err.response?.data[firstErrorKey][0]}`);
    }
  }
}

// _____ All the functiona and action of CreateQuestion : IMS_AUTHORING_SAGA  ________
function* IMS_AUTHORING_SAGA() {
  yield takeEvery(SELECT_QUESTION_BANK, selectQuestionBank);
  yield takeEvery(BASIC_INFORMATION, basicInfoSelect);
  yield takeEvery(QUESTION_CREATION_SCREEN_CHANGE, questionTypeSelection);
  yield takeEvery(QUESTION_CREATION_SCREEN_BACK, questionTypeback);
  yield takeEvery(QUESTION_CREATION_SCREEN_CANCEL, questionTypecancel);
  yield takeEvery(QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK, questionTypechooseOnlyBack);
  yield takeEvery(QUESTION_CREATION_FINAL_CALL, question_creation_apicall);
  yield takeEvery(GET_REPORT_IRT, get_report_IRT_apicall);
  yield takeEvery(GET_REPORT_QDR, get_report_QDR_apicall);
  yield takeEvery(GET_TASKS_LIST, get_task_apicall);
  yield takeEvery(GET_TASKS_DETAIL, get_task_detail_apicall);
  yield takeEvery(DELETE_TASKS, delete_task_apicall);
  yield takeEvery(APPROVE_TASKS, approve_task_apicall);
  yield takeEvery(GET_REVIEW_ALL_DATA, get_review_alldata_apicall);
  yield takeEvery(GET_NEED_REVIEW_ALL_DATA, get_review_needdata_apicall);
  yield takeEvery(CHANGE_REVIEW_STATUS, change_review_status_apicall);
  yield takeEvery(GET_USER_LIST, get_userList_apicall);
  yield takeEvery(GET_USER_DETAIL, get_userDetail_apicall);
  yield takeEvery(GET_USER_ROLES, get_userRole_apicall);
  yield takeEvery(GET_USER_QUESTION_BANK, get_userQuestionBank_apicall);
  yield takeEvery(GET_ADD_USER, get_AddUser_apicall);
  yield takeEvery(EDIT_USER_DETAIL, editUserDetail_apicall);
  yield takeEvery(GET_ORGANISATION_LIST, getOraganisationList_apicall);
  yield takeEvery(GET_ORGANISATION_DETAIL, getOraganisationDetail_apicall);
  yield takeEvery(GET_CATEGORY_LIST, getCategoryListList_apicall);
  yield takeEvery(GET_IDENTIFIER_LIST, getIdentifierListList_apicall);
  yield takeEvery(GET_LOD_LIST, getLodListList_apicall);
  yield takeEvery(GET_SUBJECT_PIPELINE_LIST, getSubjectPipelineListList_apicall);
  yield takeEvery(GET_QUESTION_BANK_LIST, getQuestionBankListList_apicall);
  yield takeEvery(CREATE_TASK, createTask_apicall);
  yield takeEvery(EDIT_TASK, editTask_apicall);
  yield takeEvery(GET_TASK_OF_USER, getTaskofUser_apicall);
  yield takeEvery(GET_PRODUCT_TYPE, getProductType_apicall);
  yield takeEvery(CREATE_TEST_BASIC_SETTING, getNewTest_basic_setting_apicall);
  yield takeEvery(CREATE_TEST_SECTION, getNewTest_Section_apicall);
  yield takeEvery(PUBLISH_TEST, getNewTestPublish);
  yield takeEvery(GET_TEST_LISTING, getTestCreationLists_apicall);
  yield takeEvery(DELETE_TEST, delete_test_apicall);
  yield takeEvery(UNPUBLISH_TEST, unpublish_test_apicall);
  yield takeEvery(PUBLISHED_TEST, publish_test_apicall);

  yield takeEvery(DUPLICATE_TEST, duplicate_test_apicall);
  yield takeEvery(PRODUCT_TYPE, getProductsType_apicall);

  yield takeEvery(GET_TEST_DETAILS, get_TestDetails_apicall);
  yield takeEvery(GET_REPLACE_QUESTION, get_Replace_apicall);

  yield takeEvery(GET_TETS_DETAILS_FOR_EDIT, get_TestDetails_for_edit_apicall);

  yield takeEvery(UPDATE_BASIC_SETTINGS, editTest_basic_setting_apicall);
  yield takeEvery(EDIT_CREATE_TEST_SECTION, geteditTest_Section_apicall);
}

export default IMS_AUTHORING_SAGA;
