import Cookies from 'js-cookie';
import React from 'react';
import profile from '../../../assets/images/profile.png';
import Header from '../common/RightPanelHeader/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { CallWithAuth } from '../../../action/apiActions';
import { GET_QUESTIONBANK } from '../../../action/apiPath';

import style from '../../../assets/styles/rightPanelStyle.module.css';

const RightPanelTopMenu = ({ setQuestionBankId, title }) => {
  // _________ GET QUESTION BANK API CALL SAVED THE DATA IN STATE _________
  const [questionBank, setQuestionBank] = useState(null);

  const getQuestionBank = async () => {
    const GetData = await CallWithAuth('GET', GET_QUESTIONBANK);
    // console.log(GetData)
    if (GetData.status && Array.isArray(GetData.res.data)) {
      setQuestionBank(GetData.res.data);
      if (setQuestionBankId != null) setQuestionBankId(GetData.res.data[0].id);
    }
  };

  useEffect(() => {
    getQuestionBank();
  }, []);

  const MasterData = [
    {
      id: 1,
      name: 'Category',
    },
    {
      id: 2,
      name: 'Identifiers',
    },
    {
      id: 3,
      name: 'LOD',
    },
    {
      id: 4,
      name: 'Question Bank',
    },
    {
      id: 5,
      name: 'Subject Pipeline',
    },
  ];

  const ReportData = [
    {
      id: 1,
      name: 'Question Distribution Report',
    },
    {
      id: 2,
      name: 'IRT parameters',
    },
  ];
  return (
    <div>
      {/* _____ TOP BAR SECTION : Same for all screens  ______ */}
      <div className={`${style.search_bar} ${style.show}`}>
        {/*_________ left side of header it is dynamic as full component _________*/}
        <div className={style.header}>
          {setQuestionBankId === null ? (
            <div className={style.header_text_title}>{title}</div>
          ) : (
            <Header
              questionBank={questionBank}
              ReportData={ReportData}
              MasterData={MasterData}
              setQuestionBankId={setQuestionBankId}
              title={title}
            />
          )}
        </div>

        {/* _________ profile section where name and image  _________*/}
        <div className={style.profile}>
          <div className={style.name}>
            <h4>
              {Cookies.get('firstName')} {Cookies.get('lastName')}{' '}
            </h4>
            <p>view profile</p>
          </div>
          <div className={style.image}>
            <img src={profile} width={48} height={48} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightPanelTopMenu;
