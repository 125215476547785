import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import style from '../../../../assets/styles/signin.module.css';
import PublicLayout from '../../../../layout/PublicLayout';
import { useToast } from '../../common/Toast';
// import { useNavigate } from 'react-router-dom';
import { CallWithOutAuth } from '../../../../action/apiActions';

// Type definitions for API responses
interface SuccessResponse {
  status: number;
  message: string;
  redirect_url: string;
}

interface ErrorResponse {
  error: string;
}

// Validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Please enter a valid email address'),
});

const ForgetPassword: React.FC = () => {
  const toast = useToast();
  //const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const RESET_PASSWORD_API_URL = `${BASE_URL}forgot-password/`;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        setApiError(null);
        //api call
        const response: any = await CallWithOutAuth('POST', RESET_PASSWORD_API_URL, {
          email: values.email,
        });

        if (response.status && response.res.data) {
          const data: SuccessResponse = response.res.data;

          if (data.status === 200) {
            toast.add(data.message);

            //window.location.replace(data.redirect_url);
            //   navigate(
            //     data.redirect_url,{ replace: true });
          }
        } else {
          // Handle error response
          const errorData: any = response.res?.data as ErrorResponse;
          setApiError(errorData.error || 'Password reset failed. Please try again.');
          toast.add(errorData.error || 'Password reset failed', { type: 'error' });
        }
      } catch (error) {
        setApiError('An unexpected error occurred. Please try again.');
        toast.add('An unexpected error occurred', { type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <PublicLayout>
      <form onSubmit={formik.handleSubmit} className={style.user_form}>
        <h2 className={style.forget_password} style={{ height: '0' }}>
          Forgot Password
          <br />
          <p className={style.some_text}>A link will be sent to this email to reset the password</p>
        </h2>
        <div className={style.mail_error_wrap} style={{visibility: apiError ? 'visible' : 'hidden'}}>
              <span className={style.warningIcon}></span>
              <div className={`${style.validation_mailError}`}>{apiError}</div>
        </div>

        <div className={style.user_pass_info}>
          <div className={style.setting_new_pass}>
            <label htmlFor="email" style={{ fontWeight: 'bold', fontSize: '14px' }}>
              Email Address
            </label>
            <br />
            <input
              type="email"
              id="email"
              placeholder="Enter your email address"
              className={style.input_field}
              {...formik.getFieldProps('email')}
              disabled={isLoading}
            />
            <div
              className={style.validationError}
              style={{ visibility: formik.touched.email && formik.errors.email ? 'visible' : 'hidden' }}
            >
              {formik.errors.email}
            </div>
          </div>
        </div>

        <div className={style.forget_pass_bt}>
          <button
            className={`${style.forget_pass_bt_signup} ${style.dynamic_button}`}
            type="submit"
            disabled={!(formik.isValid && formik.dirty) || isLoading}
            style={{
              cursor: !(formik.isValid && formik.dirty) || isLoading ? 'no-drop' : 'pointer',
              background: !(formik.isValid && formik.dirty) || isLoading ? 'lightgray' : '#00ABFB',
            }}
          >
            {isLoading ? 'Sending...' : 'Send Link'}
          </button>
        </div>
      </form>
    </PublicLayout>
  );
};

export default ForgetPassword;
