import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

type HttpMethod = 'POST' | 'PUT' | 'PATCH' | 'GET' | 'DELETE';
type ApiResponse = { status: boolean; res: AxiosResponse | undefined };

export function CallWithOutAuth(method: HttpMethod, url: string, body?: object): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    try {
      const request = {
        POST: () => axios.post(url, body),
        PUT: () => axios.put(url, body),
        PATCH: () => axios.patch(url, body),
        GET: () => axios.get(url, { params: body }),
        DELETE: () => axios.delete(url, { data: body }),
      }[method];

      if (request) {
        request()
          .then((response) => {
            resolve({ status: response.status === 200 || response.status === 201, res: response });
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      } else {
        throw new Error('Invalid HTTP method');
      }
    } catch (err: any) {
      reject(err.response);
    }
  });
}

export function CallWithAuth(method: HttpMethod, url: string, body: Record<string, any> = {}): Promise<ApiResponse> {
  const header = {
     headers: { 
      Authorization: `jwt ${Cookies.get('accessToken') || ''}`
     },
  };

  if (body.hasOwnProperty('removeToken')) {
    header.headers.Authorization = `Token ${body.token}`;
    delete body.token;
    delete body.removeToken;
  }
  if (body.hasOwnProperty('removeHeader')) {
    header.headers.Authorization ='';
    delete body.removeHeader;
    
  }
  return new Promise((resolve, reject) => {
    try {
      const request = {
        POST: () => axios.post(url, body, header),
        PUT: () => axios.put(url, body, header),
        PATCH: () => axios.patch(url, body, header),
        GET: () => axios.get(url, header),
        DELETE: () => axios.delete(url, header),
      }[method];

      if (request) {
        request()
          .then((response) => {
            resolve({ status: response.status === 200 || response.status === 201, res: response });
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      } else {
        throw new Error('Invalid HTTP method');
      }
    } catch (err: any) {
      reject(err.response);
    }
  });
}

export function CallForAuth(auth: string, method: HttpMethod, url: string, body: object = {}): Promise<ApiResponse> {
  const header = {
    headers: { Authorization: auth },
  };

  return new Promise((resolve, reject) => {
    try {
      const request = {
        POST: () => axios.post(url, body, header),
        PUT: () => axios.put(url, body, header),
        PATCH: () => axios.patch(url, body, header),
        GET: () => axios.get(url, header),
        DELETE: () => axios.delete(url, header),
      }[method];

      if (request) {
        request()
          .then((response) => {
            resolve({ status: response.status === 200 || response.status === 201, res: response });
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      } else {
        throw new Error('Invalid HTTP method');
      }
    } catch (err: any) {
      reject(err.response);
    }
  });
}
