import React from 'react';
import { Link } from 'react-router-dom';
import profile from '../../../assets/images/profile.png';
import Header from '../common/RightPanelHeader/Header';
import Cookies from 'js-cookie';

import style from '../../../assets/styles/rightPanelStyle.module.css';

const RightPanel = ({
  children,
  rightbarshow,
  setRightBarshow,
  rightbarSearchshow,
  setRightBarSearchshow,
  RemoveSideBar,
  menu,
}) => {
  return (
    <>
      <div id={style.right_panel} className={rightbarshow ? `${style.show}` : ''}>
        {children}
      </div>
    </>
  );
};

export default RightPanel;
