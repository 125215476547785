
import { Editor } from '@tinymce/tinymce-react';

interface TinyMCEProps {
  className?: string;
  toolbarList?: boolean;
  defaultValue?: string;
  onChange?: (content: string) => void;
  onRemove?: (id: string) => void;
  disabled?: boolean;
  ref?: React.Ref<any>;
  data?: Record<string, any>;
  refSetter?:(data:any)=>void;
}

const TinyMCE: React.FC<TinyMCEProps> = ({
  className = "",
  toolbarList = true,
  defaultValue = '',
  onChange,
  onRemove,
  disabled = false,
  ref,
  data = {},refSetter
}) => {
  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;

  function filterObjectByArray(arr: string[], obj: Record<string, any>) {
    for (const key in obj) {
      if (!arr.includes(key)) {
        if (onRemove) {
          onRemove(key);
        }
      }
    }
  }

  return (
    <Editor
      //ref={ref}
     // className={className}
      onInit={(evt, editor) => {
        refSetter && refSetter(editor)
        editor.on("click", (e) => {
          if (e.target.classList.contains("button-getter")) {
            console.log("Button inside editor clicked!");
          }
        });
      }}
      tinymceScriptSrc="https://manual-upload-assets.s3.ap-south-1.amazonaws.com/tinymce/tinymce.min.js"
      apiKey="gpl"
      value={defaultValue}
      // width="100%"
      // readOnly={disabled}
      onEditorChange={(_text, editor) => {
        if (onChange) {
          onChange(editor.getContent({ format: 'html' }));
          const editorDoc = editor.getDoc();
          const dynamicButtons = editorDoc.querySelectorAll(".dynamic-button");
          const deleteButtons = editorDoc.querySelectorAll(".dynamic-button .delete-btn");

          const output: string[] = [];
          dynamicButtons.forEach((btn) => {
            output.push(btn.id);
          });
          filterObjectByArray(output, data);

          deleteButtons.forEach((deleteButton) => {
            (deleteButton as HTMLElement).onclick = (e) => {
              const parentButton = deleteButton.parentElement;
              if (parentButton && onRemove) {
                onRemove(parentButton.id);
                parentButton.remove();
              }
            };
          });
        }
      }}
      init={{
        plugins: 'ai preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link math media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker editimage help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable footnotes mergetags autocorrect typography advtemplate markdown revisionhistory',
        mobile: {
          plugins: 'ai preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link math media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable footnotes mergetags autocorrect typography advtemplate',
        },
        menubar: 'file edit view insert format tools table tc help',
        toolbar: toolbarList ? "undo redo | revisionhistory | aidialog aishortcuts | blocks fontsizeinput | bold italic | align numlist bullist | link image | table math" : ''
      }}
    />
  );
}

export default TinyMCE;