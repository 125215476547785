import React, { useEffect, useRef } from 'react';
import AuthLayout from '../layout/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { CallWithAuth } from '../action/apiActions';
import { GET_QUESTION_BANK, GET_QUESTIONBANK, GET_SUBJECT_TAG } from '../action/apiPath';
import { useState } from 'react';
import profile from '../../src/assets/images/profile.png';
import Header from '../component/molecule/common/RightPanelHeader/Header';
import Cookies from 'js-cookie';
import moment from 'moment/moment';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import FilterModal from '../component/atoms/Modal/FilterModal';

import style from '../assets/styles/createQuestion.module.css';

const QuestionBank = () => {
  // ________ initialization :start ____________
  const navigate = useNavigate();
  // ________ initialization :end ____________

  // ________ button for navigating to create question ________
  const CreateQuestion = () => {
    navigate('/create-question');
  };
  // ________ button for navigating to create question ________

  // ________ PAGINATION ________

  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(5);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // alert(totalCount)
    if (totalCount < 5) {
      setLast(totalCount);
    } else {
      setLast(5);
    }
  }, [totalCount]);

  const next = () => {
    if (offset + limit >= totalCount) {
      setOffset(offset);
      setCurrentPage(currentPage);
    } else {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
    }

    if (last + limit > totalCount) {
      setLast(totalCount);
    } else {
      setLast(last + limit);
    }
  };

  const previous = () => {
    if (offset - limit < 0) {
      setOffset(offset);
      setCurrentPage(currentPage);
    } else {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
    }

    if (last <= 5) {
      setLast(last);
    } else {
      setLast((currentPage - 1) * limit);
    }
  };
  // ________ PAGINATION ________

  // _________question bank id which is top dropdown _________
  const [questionBankId, setQuestionBankId] = useState(4);

  // _________which one to show my or all _________
  const [questionCategory, setQuestionCategory] = useState(false);

  const ChangeQuestionCategory = (e) => {
    setQuestionCategory(e);
  };

  // _________ALL question created listing data _________
  const [questionResults, setquestionResults] = useState([]);

  const QuestionListing = async () => {
    const url =
      GET_QUESTION_BANK +
      questionBankId +
      '/question-listing/?draft=0' +
      '&category=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&subject_tags=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&free_tags=' +
      multiselectedFreeTag.map((item) => item.id).join(',') +
      '&question_authors=' +
      multiselectedAuthorTag.map((item) => item.id).join(',') +
      '&offset=' +
      offset +
      '&limit=' +
      limit +
      '&last_used_in=' +
      selectedYear +
      '&sort=' +
      sort +
      '&exclude_questions_used_since=' +
      selectedYearExclude +
      '&question_id=' +
      questionId;

    // console.log("url", url)
    const questionlistingData = await CallWithAuth('GET', url);
    // console.log("questionlistingData", questionlistingData)
    if (questionlistingData.status && questionlistingData.res.status === 200) {
      setquestionResults(questionlistingData.res.data.results);
      setTotalCount(questionlistingData.res.data.count);
    }
  };

  // _________ MY question created listing data __________
  const [myquestionResults, setMyquestionResults] = useState([]);

  const MyQuestionListing = async () => {
    const url =
      GET_QUESTION_BANK +
      questionBankId +
      '/question-listing/?draft=1' +
      '&category=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&subject_tags=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&free_tags=' +
      multiselectedFreeTag.map((item) => item.id).join(',') +
      '&question_authors=' +
      multiselectedAuthorTag.map((item) => item.id).join(',') +
      '&offset=' +
      offset +
      '&limit=' +
      limit +
      '&last_used_in=' +
      selectedYear +
      '&sort=' +
      sort +
      '&exclude_questions_used_since=' +
      selectedYearExclude +
      '&question_id=' +
      questionId;

    // console.log("url isssss", url)

    const myquestionlistingData = await CallWithAuth('GET', url);
    // console.log("myquestionlistingData", myquestionlistingData)
    if (myquestionlistingData.status && myquestionlistingData.res.status === 200) {
      setMyquestionResults(myquestionlistingData.res.data.results);
      setTotalCount(myquestionlistingData.res.data.count);
    }
  };

  // ________ filter modal open  ________
  const [modalFilter, setModalFilter] = useState(false);

  const openModalFilter = () => {
    setModalFilter(true);
  };

  const closeModalFilter = () => {
    setModalFilter(false);
  };

  // Get the current year
  const currentYear = new Date().getFullYear();
  // _________ PRIMARY TAG STATE INITIALIZATION _________
  const [questionId, setquestionId] = useState(0);
  const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([]);
  const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);
  const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([]);
  const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);
  const [sort, setSort] = useState('');
  // Initialize state for the selected year
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearExclude, setSelectedYearExclude] = useState(null);
  // _________ YEAR USED-> STATE INITIALIZATION _________

  // const [currentSort, setCurrentSort] = useState("")

  const handleSort = (e) => {
    if (sort === '') {
      setSort(e);
    } else if (sort === e) {
      setSort(`-${e}`);
    } else {
      setSort(e);
    }
  };

  useEffect(() => {
    if (questionCategory) {
      QuestionListing();
    } else {
      MyQuestionListing();
    }
  }, [questionBankId, questionId, offset, sort, questionCategory]);

  // QUESTION maTRIX
  const [questionMatrix, setQuestionMatrix] = useState(null);

  const QuestionMatric = async () => {
    const QuestionMatricData = await CallWithAuth('GET', GET_QUESTION_BANK + questionBankId + '/question-matrics/');
    // console.log(QuestionMatricData)
    if (QuestionMatricData.status && QuestionMatricData.res.status === 200) {
      setQuestionMatrix(QuestionMatricData.res.data);
    }
  };

  useEffect(() => {
    QuestionMatric();
  }, [questionBankId]);

  const [statusOfaction, setstatusOfaction] = useState(false);
  const [actionId, setactionId] = useState(null);

  // ACTION OF EDIT
  const ActionPerform = (i) => {
    if (statusOfaction) {
      setactionId(null);
      setstatusOfaction(false);
    } else {
      setactionId(i);
      setstatusOfaction(true);
    }
  };

  const Edit = (items) => {
    navigate('/edit-question', { state: items });
  };

  const Duplicate = async (items) => {
    const body = {
      question_id: items?.question_id,
    };
    const duplicte_data = await CallWithAuth('POST', GET_QUESTION_BANK + 'question-duplicate/', body);
    // console.log("duplicte_data",duplicte_data)
    if (duplicte_data.status && duplicte_data.res.status === 200) {
      if (questionCategory) {
        QuestionListing();
      } else {
        MyQuestionListing();
      }
      setactionId(null);
    }
  };

  // ____________ outside click close option ____________
  const rowRef = useRef();

  // const handleRowClick = (event) => {
  //   if (rowRef.current && !rowRef.current.contains(event.target)) {
  //     // Check if the click is within the action buttons
  //     const isActionButtonClick = event.target.closest('.action_buttons');

  //     if (!isActionButtonClick) {
  //       setactionId(null);
  //       setstatusOfaction(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleRowClick);

  //   return () => {
  //     document.removeEventListener('mousedown', handleRowClick);
  //   };
  // }, [handleRowClick]);

  return (
    <>
      <AuthLayout>
        {/* rightpannel top left menu according to different screen  */}
        <RightPanelTopMenu setQuestionBankId={setQuestionBankId} title={'Question Bank'} />

        {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
        <div className={style.question_bank_table_top}>
          <div className={style.question_bank}>
            {/* TOP section of the page where reports result and add new question button  */}
            <div className={style.question_bank_report}>
              <div className={style.results}>
                <div className={style.approved}>
                  <div className={style.box}>
                    <div className={style.icons}>
                      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.8985 29.3346C24.2053 29.3346 30.1837 23.3346 30.1837 16.0013C30.1837 8.66797 24.2053 2.66797 16.8985 2.66797C9.59162 2.66797 3.61328 8.66797 3.61328 16.0013C3.61328 23.3346 9.59162 29.3346 16.8985 29.3346Z"
                          fill="url(#paint0_linear_33_20396)"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M23.2498 11.5162C23.6386 11.907 23.6381 12.5402 23.2486 12.9304L15.7159 20.4771C15.3267 20.867 14.6963 20.8667 14.3074 20.4764L10.5477 16.7031C10.1586 16.3126 10.1586 15.6794 10.5477 15.2889C10.9368 14.8984 11.5677 14.8984 11.9568 15.2889L15.0126 18.3557L21.8407 11.5149C22.2302 11.1247 22.8611 11.1253 23.2498 11.5162Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_33_20396"
                            x1="30.1837"
                            y1="2.66797"
                            x2="11.3708"
                            y2="24.5912"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#9BF763" />
                            <stop offset="1" stopColor="#25AB5B" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.score}>
                      <p>Approved Question</p>
                      <span>{questionMatrix?.approved}</span>
                    </div>
                  </div>
                </div>

                <div className={style.Rejected}>
                  <div className={style.box}>
                    <div className={style.icons}>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.9993 29.3346C23.3327 29.3346 29.3327 23.3346 29.3327 16.0013C29.3327 8.66797 23.3327 2.66797 15.9993 2.66797C8.66602 2.66797 2.66602 8.66797 2.66602 16.0013C2.66602 23.3346 8.66602 29.3346 15.9993 29.3346Z"
                          fill="url(#paint0_linear_33_20413)"
                        />
                        <path
                          d="M12.2285 12.2266L19.771 19.769"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.229 19.769L19.7715 12.2266"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_33_20413"
                            x1="29.3327"
                            y1="2.66797"
                            x2="10.5302"
                            y2="24.6584"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FFA78F" />
                            <stop offset="1" stopColor="#F23E2C" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.score}>
                      <p>Rejected Questions</p>
                      <span>{questionMatrix?.rejected}</span>
                    </div>
                  </div>
                </div>

                <div className={style.Review}>
                  <div className={style.box}>
                    <div className={style.icons}>
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                          cx="13.0007"
                          cy="12.9974"
                          r="10.6667"
                          transform="rotate(-90 13.0007 12.9974)"
                          stroke="url(#paint0_linear_33_20429)"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <path
                          d="M13.0006 2.33073C7.10961 2.33073 2.33398 7.10636 2.33398 12.9974C2.33398 18.8884 7.10961 23.6641 13.0007 23.6641"
                          stroke="url(#paint1_linear_33_20429)"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_33_20429"
                            x1="2.33398"
                            y1="23.6641"
                            x2="26.334"
                            y2="20.3307"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FF8800" />
                            <stop offset="0.380208" stopColor="#FFCF00" />
                            <stop offset="1" stopColor="#FFE8CE" stopOpacity="0" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_33_20429"
                            x1="13.2019"
                            y1="24.2678"
                            x2="13.2019"
                            y2="2.33073"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FF8800" />
                            <stop offset="1" stopColor="#FFCF00" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.score}>
                      <p>Review Questions</p>
                      <span>{questionMatrix?.in_review}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.add_question}>
                <button className={` ${style.add_new_question} ${style.pointer}`} onClick={() => CreateQuestion()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      fill="white"
                    />
                    <path
                      d="M7.5 12H16.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 16.5V7.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span> Add New Questions</span>
                </button>
              </div>
            </div>

            {/* TABLE SECTION OF THIS PAGE  */}
            <div className={style.table_content_report}>
              <div className={style.top_content_table}>
                <div className={style.table_title}>
                  <span
                    className={questionCategory ? ` ${style.pointer}` : ` ${style.underline_active} ${style.pointer}`}
                    onClick={() => ChangeQuestionCategory(false)}
                  >
                    Draft Questions
                  </span>
                  <span
                    className={questionCategory ? ` ${style.underline_active} ${style.pointer}` : ` ${style.pointer}`}
                    onClick={() => ChangeQuestionCategory(true)}
                  >
                    All Questions
                  </span>
                </div>

                <div className={style.search_listing}>
                  <div className={style.tag_icon}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.2598 19.2592L16.1406 16.8594"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Question ID</span>
                  </div>

                  <div className={style.input_search}>
                    <input
                      type="search"
                      id="searchInput"
                      placeholder="Search for Question ID to refine list"
                      onChange={(e) => setquestionId(e.target.value)}
                    />
                  </div>
                </div>

                <div className={style.filter}>
                  <div className={style.pointer} onClick={() => openModalFilter()}>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill={modalFilter ? '#00ABFB' : 'none'}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                        stroke={modalFilter ? '#00ABFB' : '#212529'}
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span style={{ color: modalFilter ? '#00ABFB' : '#212529' }}>Filter</span>
                  </div>
                </div>
              </div>

              {/* TABLE LISTING OF ALL AND MY QUESTION  */}
              <div className={style.sub_head_content_table}>
                <table>
                  <thead>
                    <tr>
                      <th className={` ${style.very_first} ${style.pointer}`}>Question ID and Tags</th>
                      <th className={` ${style.first} ${style.pointer}`}>
                        <span>General Tags</span>
                        {/* <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                          <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                          <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                          <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                        </svg> */}
                      </th>
                      <th className={` ${style.second} ${style.pointer}`} onClick={() => handleSort('created_by')}>
                        {' '}
                        <span>Author</span>
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.third} ${style.pointer}`} onClick={() => handleSort('created_at')}>
                        <span>Created On</span>
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fourth} ${style.pointer}`} onClick={() => handleSort('difficulty')}>
                        <span>LOD</span>
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fifth} ${style.pointer}`} onClick={() => handleSort('status')}>
                        <span>Status</span>
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  {/* ALL AND MY QUESTION according to ternary operator */}
                  {questionCategory ? (
                    <tbody>
                      {questionResults?.map((items, i) => (
                        <tr className={style.table_body_content} ref={rowRef}>
                          <td>
                            <div className={style.question_id_tag}>
                              <div className={style.id}>
                                {items.question_id}
                                {/* {items.question_id?.map((items, i) => items)} */}
                              </div>
                              <div className={style.tag}>
                                <p>{items.question_short_identifier}</p>
                                <span>{items.primary_tag.slug}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              {items.free_tags.map((item, i) => (
                                <button className={style.general_button}>{item.name}</button>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by {items.created_by}</div>
                          </td>
                          <td>
                            <div className={style.created_date}>{moment(items.created_at).format('Do MMM YYYY')}</div>
                          </td>
                          <td>
                            <div className={style.lod}>{items.difficulty}</div>
                          </td>
                          <td>
                            <div className={style.status}>
                              {' '}
                              <span>Status</span>
                            </div>
                          </td>
                          <td>
                            <div className={` ${style.action} ${style.pointer}`}>
                              <svg
                                onClick={() => ActionPerform(i)}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                  fill="#272727"
                                />
                              </svg>
                              <div
                                className={style.action_button}
                                style={{ display: actionId === i ? 'block' : 'none' }}
                              >
                                <div className={style.edit} onClick={() => Edit(items)}>
                                  {' '}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{' '}
                                  <span>Edit</span>
                                </div>
                                <div className={style.duplicate} onClick={() => Duplicate(items)}>
                                  <svg
                                    width="13"
                                    height="16"
                                    viewBox="0 0 13 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                      fill="#212529"
                                    />
                                  </svg>
                                  <span> Duplicate</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      {myquestionResults?.map((items, i) => (
                        <tr className={style.table_body_content} ref={rowRef}>
                          <td>
                            <div className={style.question_id_tag}>
                              <div className={style.id}>
                                {/* {items.question_id?.map((items, i) => items)} */}
                                {items.question_draft_id}
                              </div>
                              <div className={style.tag}>
                                <p>{items?.question_short_identifier}</p>
                                <span>{items?.primary_tag?.slug}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              {items.free_tags.map((item, i) => (
                                <button className={style.general_button}>{item.name}</button>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by {items.created_by}</div>
                          </td>
                          <td>
                            <div className={style.created_date}>{moment(items.created_at).format('Do MMM YYYY')}</div>
                          </td>
                          <td>
                            <div className={style.lod}>{items.difficulty}</div>
                          </td>
                          <td>
                            <div className={style.status}>
                              {' '}
                              <span>Status</span>
                            </div>
                          </td>
                          <td>
                            <div className={style.action}>
                              <svg
                                onClick={() => ActionPerform(i)}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                  fill="#272727"
                                />
                              </svg>
                              <div
                                className={style.action_button}
                                style={{ display: actionId === i ? 'block' : 'none' }}
                              >
                                <div className={style.edit} onClick={() => Edit(items)}>
                                  {' '}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{' '}
                                  <span>Edit</span>
                                </div>
                                {/* <div className={style.duplicate}>
                                  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z" fill="#212529" />
                                  </svg>
                                  <span> Duplicate</span>
                                </div> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            {/*______________________________ PAGINATION ______________________________  */}

            <div className={style.pagination}>
              <div className={style.number}>
                The Page you are on &nbsp; &nbsp; <button className={style.currentpage}> {currentPage} </button>
              </div>
              <div className={style.line}>
                <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line
                    opacity="0.1"
                    x1="0.695312"
                    y1="23.5"
                    x2="0.695311"
                    y2="0.5"
                    stroke="#764FDB"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className={style.change}>
                <button onClick={() => previous()}>
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.5622 12.7803C10.2635 13.0732 9.7793 13.0732 9.48064 12.7803L5.14685 8.53033C4.84819 8.23744 4.84819 7.76256 5.14685 7.46967L9.48064 3.21967C9.7793 2.92678 10.2635 2.92678 10.5622 3.21967C10.8608 3.51256 10.8608 3.98744 10.5622 4.28033L6.76921 8L10.5622 11.7197C10.8608 12.0126 10.8608 12.4874 10.5622 12.7803Z"
                      fill="#00ABFB"
                    />
                  </svg>
                </button>
                <div className={style.current}>
                  {offset}-{last} of {totalCount}
                </div>
                <button onClick={() => next()}>
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.63122 3.21967C6.92989 2.92678 7.41413 2.92678 7.7128 3.21967L12.0466 7.46967C12.3452 7.76256 12.3452 8.23744 12.0466 8.53033L7.7128 12.7803C7.41413 13.0732 6.92989 13.0732 6.63122 12.7803C6.33256 12.4874 6.33256 12.0126 6.63122 11.7197L10.4242 8L6.63122 4.28033C6.33256 3.98744 6.33256 3.51256 6.63122 3.21967Z"
                      fill="#00ABFB"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/*______________________________ PAGINATION ______________________________  */}
          </div>
        </div>

        {/* FILTER MODAL CALL for filteration on searching  */}
        <FilterModal
          usedFor={0}
          questionCategory={questionCategory}
          QuestionListing={QuestionListing}
          MyQuestionListing={MyQuestionListing}
          modalFilter={modalFilter}
          closeModalFilter={closeModalFilter}
          multiselectedSubjectTag={multiselectedSubjectTag}
          setmultiSelectedSubjectTag={setmultiSelectedSubjectTag}
          multiselectedFreeTag={multiselectedFreeTag}
          setmultiSelectedFreeTag={setmultiSelectedFreeTag}
          multiselectedCategoryTag={multiselectedCategoryTag}
          setmultiSelectedCategoryTag={setmultiSelectedCategoryTag}
          multiselectedAuthorTag={multiselectedAuthorTag}
          setmultiSelectedAuthorTag={setmultiSelectedAuthorTag}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          selectedYearExclude={selectedYearExclude}
          setSelectedYearExclude={setSelectedYearExclude}
          currentYear={currentYear}
        />
      </AuthLayout>
    </>
  );
};

export default QuestionBank;
