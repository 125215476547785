import React, { useEffect, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';

interface DescriptiveTextProps {
  body: any[];
  questionbutton: number;
  setbodyformultiquestion: (body: any[]) => void;
}

const DescriptveText: React.FC<DescriptiveTextProps> = ({ body, questionbutton, setbodyformultiquestion }) => {
  const [explanation_text, setExplanation_text] = useState(body[questionbutton]?.explaination_text);
  const [compose_text, setCompose_text] = useState(body[questionbutton]?.compose_question);
  const [explanation_video, setExplanation_video] = useState(body[questionbutton]?.explaination_video);
  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      compose_question: compose_text,
      explaination_video: explanation_video || null,
      explaination_text: explanation_text || null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [compose_text, explanation_text, explanation_video]);

  function b64DecodeUnicode(str:string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  return (
    <div className={style.tinymce_text}>
      {/* add compose_text with tnymce*/}
      <div className={style.compose_text}>
        <p>
          Compose Question <b className={style.red_mendantory}>*</b>
        </p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            defaultValue={compose_text != null ? b64DecodeUnicode(compose_text):""}
            onChange={(text) => setCompose_text(btoa(text))}
          />
        </div>
      </div>
      <div className={style.explanation_line}>
        <hr className={style.line} />
      </div>
      {/* add explanation_video*/}
      <div className={style.explanation_text}>
        <p>Add Explanation Video</p>
        <div className={style.explanation_video}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.17188 14.8287L14.8287 9.17188"
              stroke="#272727"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.05063 11.293L5.63642 12.7072C4.07432 14.2693 4.07432 16.8019 5.63642 18.364C7.19851 19.9261 9.73117 19.9261 11.2933 18.364L12.7075 16.9498"
              stroke="#272727"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.293 7.05063L12.7072 5.63642C14.2693 4.07432 16.8019 4.07432 18.364 5.63642C19.9261 7.19851 19.9261 9.73117 18.364 11.2933L16.9498 12.7075"
              stroke="#272727"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            type="text"
            value={explanation_video}
            placeholder="Attach Video Video Link"
            onChange={({ target: { value } }) => setExplanation_video(value)}
          />
        </div>
      </div>
      {/* add explanation_text with tnymce*/}
      <div className={style.explanation_text}>
        <p>Add Explanation Text</p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            defaultValue={explanation_text != null ? b64DecodeUnicode(explanation_text):""}
            onChange={(text) => setExplanation_text(btoa(text))}
          />
        </div>
      </div>
    </div>
  );
};

export default DescriptveText;
