import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';

import style from '../../../../../../assets/styles/createQuestion.module.css';

const TITA_specificRange = ({ body, questionbutton, setbodyformultiquestion }: { body: any; questionbutton: number; setbodyformultiquestion: (body: any) => void }) => {
  //_______ redux selector _______
  const selector = useSelector((state:any) => state.QuestionScreenReducer);
  const basicInfo = useSelector((state:any) => state.BasicInformationReducer);

  // _______ TO SET THE RANGE  OF SPECIFIC FORMAT OF TITA _______
  const rangerAnswer = (e:any, key:any) => {
    const rangeText = [...correntAnswerTita];

    if (key === 'from') {
      rangeText[0] = e.target.value;
    } else if (key === 'to') {
      rangeText[1] = e.target.value;
    } else if (key === 'unit') {
      rangeText[2] = e.target.value;
    }

    setCorrentAnswerTita(rangeText);
  };

  // state of explanation_text and compose text
  const [questionDirection, setQuestionDirection] = useState(body[questionbutton]?.question_short_identifier);
  const [explanation_text, setExplanation_text] = useState(body[questionbutton]?.explaination_text);
  const [compose_text, setCompose_text] = useState(body[questionbutton]?.compose_question);
  const [explanation_video, setExplanation_video] = useState(body[questionbutton]?.explaination_video);
  const [lastIndex, setlastIndex] = useState(null);
  const [randomise_answers, setRandomise_answers] = useState(body[questionbutton]?.randomise_answers);
  const [answerFormat, setanswerFormat] = useState(body[questionbutton]?.answer_format);
  const [videoId, setvideoId] = useState(body[questionbutton]?.video_id);

  // total number of answer
  const [answers, setAnswers] = useState(['', '', '', '']);
  const [correntAnswer, setCorrentAnswer] = useState('');

  // correct answer for TITA
  const [correntAnswerTita, setCorrentAnswerTita] = useState(body[questionbutton]?.correct_answer?.split(',') || []);

  // function to add the multiple options
  function AddAnswerCall() {
    setAnswers((current) => [...current, '']);
  }

  // function to close the multiple options
  function CloseAnswerCall() {
    const newArray = [...answers];
    newArray.pop();
    setAnswers(newArray);
  }

  // encode tunymce to normal text
  // const decode = (item, key, index) => {
  //   if (item.length > 0) {
  //     // Your HTML content in a variable
  //     const htmlString = item;

  //     // Create a new DOMParser
  //     const parser = new DOMParser();

  //     // Parse the HTML string
  //     const parsedHtml = parser.parseFromString(htmlString, 'text/html');

  //     // Access the innerHTML property of the first child
  //     const innerHTML = parsedHtml.body.firstChild.innerHTML;

  //     // Store innerHTML in a variable
  //     const myVariable = innerHTML;

  //     // Now you have the innerHTML content (i.e., "helljhabdsaho") saved in the `myVariable` variable
  //     if (key === setAnswers) {
  //       const answerinNumber = [...answers];
  //       answerinNumber[index] = myVariable;
  //       key(answerinNumber);
  //     } else {
  //       key(myVariable);
  //     }
  //   } else {
  //   }
  // };

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionDirection,
      compose_question: compose_text,
      correct_answer: correntAnswerTita.toString(),
      last_option_idx: lastIndex,
      randomise_answers: randomise_answers,
      explaination_video: explanation_video || null,
      explaination_text: explanation_text || null,
      answer_format: answerFormat,
      task_label: null,
      video_id: videoId || null,

      // "difficulty":basicInfo.difficulty,
      // "question_type":body[questionbutton]?.question_type_id,
      // "format":basicInfo.format
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [
    questionDirection,
    compose_text,
    correntAnswerTita,
    answerFormat,
    explanation_text,
    answers,
    correntAnswer,
    lastIndex,
    randomise_answers,
    explanation_video,
    videoId,
  ]);

  function b64DecodeUnicode(str:string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  // console.log("correntAnswerTita",correntAnswerTita)
  return (
    <>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
      <div className={style.tinymce_text}>
        {/* question type getting from screen 2.1 */}
        {/* <div className='question-creating' style={{ height: "5%" }}>
                    <div className=''>
                        <div className='question-creating-title'>
                            <span>{selector.questiontype.question_type_id.category} - {selector.questiontype.question_type_id.name} </span>

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div> */}

        {/* add explanation_text with tnymce*/}
        <div className={style.Question_Direction}>
          <p>
            Enter Question Short Identifier <b className={style.red_mendantory}>*</b>
          </p>
          <textarea
            //type="text"
            placeholder="Enter Question Short Identifier"
            className={style.question_identifier}
            value={questionDirection}
            onChange={(e) => setQuestionDirection(e.target.value)}
          />

          {/* <Editor
                       value={questionDirection}
                       onEditorChange={(newText, editor) => {
                           setQuestionDirection(editor.getContent({ format: 'text' }))
                       }}
                    /> */}
        </div>

        {/* <button onClick={() => check()}>click</button> */}
        {/* <div dangerouslySetInnerHTML={{ __html: explanation_text }} /> */}

        {/* add compose_text with tnymce*/}
        <div className={style.compose_text}>
          <p>
            Compose Question <b className={style.red_mendantory}>*</b>
          </p>
          <Editor
            apiKey="43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz"
            value={compose_text != null ? b64DecodeUnicode(compose_text):""}
            onEditorChange={(newText, editor) => {
              setCompose_text(btoa(editor.getContent({ format: 'html' })));
            }}
          />
        </div>

        {/* add enter-answer with tnymce*/}
        <div className={style.correctAnswer_range}>
          <p>Enter Correct Answer Range </p>
          <div className={style.range_calculate}>
            <div className={style.range}>
              <div className={style.from}>
                <label>From</label>
                <br />
                <input
                  type="number"
                  className={style.range_input}
                  value={correntAnswerTita[0]}
                  onChange={(e) => rangerAnswer(e, 'from')}
                />
              </div>
              <div className={style.to}>
                <label>To</label>
                <br />
                <input
                  type="number"
                  className={style.range_input}
                  value={correntAnswerTita[1]}
                  onChange={(e) => rangerAnswer(e, 'to')}
                />
              </div>
            </div>
            <div className={style.rangetext}>
              <label>Answer Unit</label>
              <br />
              <input
                type="text"
                className={style.range_input}
                value={correntAnswerTita[2]}
                onChange={(e) => rangerAnswer(e, 'unit')}
              />
            </div>
          </div>
        </div>

        {/* explanation line  */}
        <div className={style.explanation_line}>
          <hr className={style.line} />
        </div>

        {/* add explanation_video*/}
        <div className={style.explanation_text}>
          <p>
            Add Explanation Video <b className={style.red_mendantory}>*</b>
          </p>
          <div className={style.explanation_video}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.17188 14.8287L14.8287 9.17188"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.05063 11.293L5.63642 12.7072C4.07432 14.2693 4.07432 16.8019 5.63642 18.364C7.19851 19.9261 9.73117 19.9261 11.2933 18.364L12.7075 16.9498"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.293 7.05063L12.7072 5.63642C14.2693 4.07432 16.8019 4.07432 18.364 5.63642C19.9261 7.19851 19.9261 9.73117 18.364 11.2933L16.9498 12.7075"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              value={explanation_video}
              placeholder="Attach Vimeo Video Link"
              onChange={(e) => setExplanation_video(e.target.value)}
            />
          </div>
        </div>

        {/* add explanation_text with tnymce*/}
        <div className={style.explanation_text}>
          <p>
            Add Explanation Text <b className={style.red_mendantory}>*</b>
          </p>
          <Editor
            apiKey="43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz"
            value={explanation_text != null ? b64DecodeUnicode(explanation_text):""}
            onEditorChange={(newText, editor) => {
              setExplanation_text(btoa(editor.getContent({ format: 'html' })));
            }}
          />
        </div>

        <div className={style.explanation_text}>
          <p>
            Video Id <b className={style.red_mendantory}>*</b>
          </p>
          <div className={style.explanation_video}>
            <input
              type="text"
              value={videoId}
              placeholder="Video Cipher ID"
              onChange={(e) => setvideoId(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
    </>
  );
};

export default TITA_specificRange;
