import { GET_REVIEW_DATA } from '../action/apiPath';
import {
  APPROVE_TASKS,
  BASIC_INFORMATION,
  CHANGE_ADD_TEST_SCREEN,
  CHANGE_EDIT_TEST_SCREEN,
  CHANGE_IRT_SCREEN,
  CHANGE_MASTER_SCREEN,
  CHANGE_ORGANISATION_SCREEN,
  CHANGE_QDR_SCREEN,
  CHANGE_QUESTION_CATEGORY,
  CHANGE_REVIEW_STATUS,
  CHANGE_TEST_SCREEN,
  CHANGE_USER_SCREEN,
  CLEAR_ALL_DATA,
  CREATE_TASK,
  CREATE_TEST,
  CREATE_TEST_BASIC_SETTING,
  CREATE_TEST_SECTION,
  DELETE_TASKS,
  DELETE_TEST,
  DUPLICATE_TEST,
  EDIT_CREATE_TEST_SECTION,
  EDIT_TASK,
  EDIT_USER_DETAIL,
  GET_ADD_USER,
  GET_CATEGORY_LIST,
  GET_IDENTIFIER_LIST,
  GET_IRT_PARAMETER,
  GET_LOD_LIST,
  GET_NEED_REVIEW_ALL_DATA,
  GET_ORGANISATION,
  GET_ORGANISATION_DETAIL,
  GET_ORGANISATION_LIST,
  GET_PRODUCT_TYPE,
  GET_QUESTION_BANK_LIST,
  GET_REPLACE_QUESTION,
  GET_REPORT,
  GET_REPORT_IRT,
  GET_REPORT_QDR,
  GET_REVIEW_ALL_DATA,
  GET_ROLES,
  GET_SUBJECT_PIPELINE_LIST,
  GET_TASKS_DETAIL,
  GET_TASKS_LIST,
  GET_TASK_OF_USER,
  GET_TASK_SCREEN,
  GET_TASK_SCREEN_WITH_ID,
  GET_TEST_DETAILS,
  GET_TEST_LISTING,
  GET_TETS_DETAILS_FOR_EDIT,
  GET_USER_DETAIL,
  GET_USER_LIST,
  GET_USER_ORGANISATION,
  GET_USER_QUESTION_BANK,
  GET_USER_ROLES,
  PRODUCT_TYPE,
  PUBLISHED_TEST,
  PUBLISH_TEST,
  QUESTION_CREATION_FINAL_CALL,
  QUESTION_CREATION_SCREEN_BACK,
  QUESTION_CREATION_SCREEN_CANCEL,
  QUESTION_CREATION_SCREEN_CHANGE,
  QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK,
  SCREEN_TO_ZERO,
  SELECT_QUESTION_BANK,
  TOGGLE_OPEN_STATUS,
  UNPUBLISH_TEST,
  UPDATE_BASIC_SETTINGS,
} from './constant';

// _____________________________________________ QUESTION AUTHORING _____________________________________________

// ACTION: for selecting questionBank id for the REDUCER: CreateQuestionReducer
export const CreateQuestionAction = (data, id) => {
  return {
    type: SELECT_QUESTION_BANK,
    data: data,
    id: id,
  };
};

// ACTION: for selecting Basic Information for the REDUCER: BasicInformationReducer
export const BasicInformation = (data, id, format) => {
  // console.log("data,id", data, id);
  return {
    type: BASIC_INFORMATION,
    data: data,
    id: id,
    format: format,
  };
};

// ACTION: for changing the screen accordingly for the REDUCER: QuestionScreenReducer
export const QuestionCreationScreen = (data) => {
  return {
    type: QUESTION_CREATION_SCREEN_CHANGE,
    data: data,
  };
};

// ACTION: to go back to (question creation 2.1 screen ) from (question creation 2.2 screen ) accordingly for the REDUCER: QuestionScreenReducer
export const QuestionCreationScreenBack = () => {
  return {
    type: QUESTION_CREATION_SCREEN_BACK,
  };
};

// ACTION: to go back to (question creation 2.1 screen ) from (question creation 2.2 screen ) accordingly for the REDUCER: QuestionScreenReducer
export const QuestionCreationScreenChooseOnlyBack = (data) => {
  // console.log("data", data);
  return {
    type: QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK,
    data: data,
  };
};

// ACTION: to go back to QUESTION BANK SCREEN from ANY SCREEN accordingly for the REDUCER: QuestionScreenReducer
export const QuestionCreationScreenCancel = () => {
  return {
    type: QUESTION_CREATION_SCREEN_CANCEL,
  };
};

export const QuestionCreationFinalCall = (data, id) => {
  // console.log("final check", data, id);
  return {
    type: QUESTION_CREATION_FINAL_CALL,
    data: data,
    id: id,
  };
};

// clear all the data
export const clearAllData = () => ({
  type: CLEAR_ALL_DATA,
});

// _____________________________________________ QUESTION AUTHORING _____________________________________________

// _____________________________________________ REPORT _____________________________________________

export const Get_QDR_Report = (subjectId) => {
  return {
    type: GET_REPORT_QDR,
    subjectId,
  };
};

export const Change_qdr_status = (status) => {
  return {
    type: CHANGE_QDR_SCREEN,
    status: status,
  };
};

export const Get_IRT_Report = () => {
  return {
    type: GET_REPORT_IRT,
  };
};

export const Change_irt_status = (status) => {
  return {
    type: CHANGE_IRT_SCREEN,
    status: status,
  };
};

// _____________________________________________ REPORT _____________________________________________

// _____________________________________________ TASK MANAGEMENT _____________________________________________

export const Get_Task_Management = (key, sort) => {
  return {
    type: GET_TASKS_LIST,
    key,
    sort,
  };
};

export const Get_Task_Detail = (id) => {
  return {
    type: GET_TASKS_DETAIL,
    id,
  };
};

export const Delete_Task = (id) => {
  return {
    type: DELETE_TASKS,
    id,
  };
};

export const Approve_Task = (id) => {
  return {
    type: APPROVE_TASKS,
    id,
  };
};

export const Create_Task = (body) => {
  return {
    type: CREATE_TASK,
    body,
  };
};

export const Task_Screen_Change = (screen) => {
  return {
    type: GET_TASK_SCREEN,
    screen: screen,
  };
};

export const EditTasks = (id, body) => {
  return {
    type: EDIT_TASK,
    id,
    body,
  };
};

export const Task_Screen_Change_With_TaskId = (taskId, screen) => {
  return {
    type: GET_TASK_SCREEN_WITH_ID,
    taskId,
    screen,
  };
};

export const Task_of_User = (task_type) => {
  return {
    type: GET_TASK_OF_USER,
    task_type,
  };
};

// _____________________________________________ TASK MANAGEMENT _____________________________________________

// _____________________________________________ REVIEW _____________________________________________

export const Get_Review = (sort, author, subject, free_tags) => {
  return {
    type: GET_REVIEW_ALL_DATA,
    sort: sort,
    author: author,
    subject: subject,
    free_tags: free_tags,
  };
};

export const Get_Need_Review = (sort, author, subject, free_tags) => {
  return {
    type: GET_NEED_REVIEW_ALL_DATA,
    sort: sort,
    author: author,
    subject: subject,
    free_tags: free_tags,
  };
};

export const Change_Review_Status = (event, id) => {
  return {
    type: CHANGE_REVIEW_STATUS,
    event: event,
    id: id,
  };
};

export const Change_Question_catoegory_InRevie = (status) => {
  return {
    type: CHANGE_QUESTION_CATEGORY,
    status,
  };
};

export const ToggleOpen_InRevie = (status) => {
  return {
    type: TOGGLE_OPEN_STATUS,
    status,
  };
};

// _____________________________________________ USER MANAGEMENT _____________________________________________
export const GetUserLists = (keyword, sort) => {
  return {
    type: GET_USER_LIST,
    keyword: keyword,
    sort: sort,
  };
};

export const GetUserDetail = (id) => {
  return {
    type: GET_USER_DETAIL,
    id: id,
  };
};

export const UserScreenChange = (screen) => {
  return {
    type: CHANGE_USER_SCREEN,
    screen: screen,
  };
};

export const GetRoles = () => {
  return {
    type: GET_USER_ROLES,
  };
};

export const GetAddUser = (body) => {
  return {
    type: GET_ADD_USER,
    body: body,
  };
};

export const EdituserDetails = (id, body) => {
  return {
    type: EDIT_USER_DETAIL,
    id: id,
    body: body,
  };
};

export const GetOraganisationList = () => {
  return {
    type: GET_ORGANISATION_LIST,
  };
};

export const GetOraganisationDetails = (id) => {
  return {
    type: GET_ORGANISATION_DETAIL,
    id: id,
  };
};

export const ChangeOrganisation = (status) => {
  return {
    type: CHANGE_ORGANISATION_SCREEN,
    status: status,
  };
};

export const getUserQuestionBank = () => {
  return {
    type: GET_USER_QUESTION_BANK,
  };
};

// _______________________ Mater section _______________________

export const MatserScreenChange = (screen) => {
  return {
    type: CHANGE_MASTER_SCREEN,
    screen: screen,
  };
};

export const Get_All_Category = (key, sort) => {
  return {
    type: GET_CATEGORY_LIST,
    key: key,
    sort: sort,
  };
};

export const Get_All_Identifier = (key, sort) => {
  return {
    type: GET_IDENTIFIER_LIST,
    key: key,
    sort: sort,
  };
};

export const Get_All_LOD = (key, sort) => {
  return {
    type: GET_LOD_LIST,
    key: key,
    sort: sort,
  };
};

export const Get_All_Subject_pipeline = (key, sort) => {
  return {
    type: GET_SUBJECT_PIPELINE_LIST,
    key: key,
    sort: sort,
  };
};

export const Get_All_Question_bank = (key, sort) => {
  return {
    type: GET_QUESTION_BANK_LIST,
    key: key,
    sort: sort,
  };
};

export const Get_All_Product_type = () => {
  return {
    type: GET_PRODUCT_TYPE,
  };
};

// _______________________ Test Creation_______________________

export const change_test_screen = (screen) => {
  return {
    type: CHANGE_TEST_SCREEN,
    screen,
  };
};

export const Add_Basic_Setting_Test = (body) => {
  return {
    type: CREATE_TEST_BASIC_SETTING,
    body,
  };
};

export const Add_Section_Test = (body, testCreationId) => {
  return {
    type: CREATE_TEST_SECTION,
    body,
    testCreationId,
  };
};

export const Edit_Section_Test = (body, testCreationId) => {
  return {
    type: EDIT_CREATE_TEST_SECTION,
    body,
    testCreationId,
  };
};

export const Publish_Test = (body, testCreationId) => {
  return {
    type: PUBLISH_TEST,
    body,
    testCreationId,
  };
};

export const changeAdd_test_screen = (screen) => {
  return {
    type: CHANGE_ADD_TEST_SCREEN,
    screen,
  };
};

export const changeEdit_test_screen = (screen) => {
  return {
    type: CHANGE_EDIT_TEST_SCREEN,
    screen,
  };
};

export const Get_Test_list = (sort, filterParams) => {
  // console.log('Dispatching Get_Test_list action with sort:', sort, 'and filterParams:', filterParams);

  return {
    type: GET_TEST_LISTING,
    sort,
    filterParams,
  };
};

export const Delete_Test = (id) => {
  return {
    type: DELETE_TEST,
    id,
  };
};

export const Unpublish_Test = (id) => {
  return {
    type: UNPUBLISH_TEST,
    id,
  };
};

export const Published_Test = (id) => {
  return {
    type: PUBLISHED_TEST,
    id,
  };
};

export const Duplicate_Test = (id) => {
  return {
    type: DUPLICATE_TEST,
    id,
  };
};

export const Get_Product_Type = (id) => {
  return {
    type: PRODUCT_TYPE,
  };
};

export const Get_Test_Deatils = (id) => {
  return {
    type: GET_TEST_DETAILS,
    id,
  };
};

export const Get_Replace_question = (id, otherId) => {
  return {
    type: GET_REPLACE_QUESTION,
    id,
    otherId,
  };
};

export const Get_Test_Detail_For_Edit = (id) => {
  return {
    type: GET_TETS_DETAILS_FOR_EDIT,
    id,
  };
};

export const Edit_Test = (id, body) => {
  return {
    type: UPDATE_BASIC_SETTINGS,
    id,
    body,
  };
};
