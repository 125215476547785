import React, { useEffect, useState } from 'react';

import style from '../../../../assets/styles/organisationManagement.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetOraganisationDetails, GetOraganisationList } from '../../../../redux/action';

const OrganisationList = ({ setScreen, UserData, setsort, setKeyword, setid }) => {
  // ________ filter modal open  ________
  const [modalFilter, setModalFilter] = useState(false);

  //_________  redux api call _________
  const { organisationList } = useSelector((state) => state.GetAllData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOraganisationList());
  }, []);

  // console.log(organisationList);
  return (
    <div>
      <div className={style.question_bank}>
        {/* TABLE SECTION OF THIS PAGE  */}
        <div className={style.table_content_report}>
          <div className={style.top_content_table}>
            <div className={` ${style.search_listing} ${style.user_management_search_listing}`}>
              <div className={style.tag_icon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2598 19.2592L16.1406 16.8594"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className={style.input_search}>
                <input
                  type="search"
                  id="searchInput"
                  placeholder="Search by Organisation"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
            </div>

            {/* <div className='filter user_management_filter'> */}
            {/* <div className='pointer'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill={modalFilter ? "#00ABFB" : "none"} xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z" stroke={modalFilter ? "#00ABFB" : "#212529"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span style={{ color: modalFilter ? "#00ABFB" : "#212529" }}>Filter</span>
                            </div> */}
            {/* </div> */}

            <div className={style.add_question}>
              <div className={style.filter}>
                <div className={style.pointer}>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill={modalFilter ? '#00ABFB' : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                      stroke={modalFilter ? '#00ABFB' : '#212529'}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ color: modalFilter ? '#00ABFB' : '#212529' }}>Filter By</span>
                </div>
              </div>
              <div>
                <input type="checkbox" />
                <span>Question Author</span> &nbsp;
              </div>
              <div>
                <input type="checkbox" />
                <span>Test Author</span> &nbsp;
              </div>
              <div>
                <input type="checkbox" />
                <span>Admin</span> &nbsp;
              </div>
            </div>
          </div>

          {/* TABLE LISTING OF ALL AND MY QUESTION  */}
          <div className={style.sub_head_content_table}>
            <table>
              <thead>
                <tr>
                  <th className={` ${style.first} ${style.pointer}`} onClick={() => setsort('organization')}>
                    <span>Organisation</span>
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                        fill="#00ABFB"
                      />
                    </svg>
                  </th>

                  <th className={` ${style.first} ${style.pointer}`}>
                    <span>Super Admin</span>
                  </th>

                  <th className={` ${style.second} ${style.pointer}`}>
                    {' '}
                    <span>Number of Users</span>
                  </th>
                  <th className={` ${style.third} ${style.pointer}`}>
                    <span>Subscription</span>
                  </th>
                  <th className={` ${style.fourth} ${style.pointer}`}>
                    <span>Payment</span>
                  </th>
                  <th className={` ${style.fifth} ${style.pointer}`} onClick={() => setsort('is_active')}>
                    <span>Status</span>
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                        fill="#00ABFB"
                      />
                    </svg>
                  </th>
                  <th></th>
                </tr>
              </thead>

              {/* ALL AND MY QUESTION according to ternary operator */}
              <tbody>
                {organisationList?.map((items, i) => (
                  <tr className={style.table_body_content}>
                    <td>
                      <div className={style.user_management_table_body_content}>{items.name}</div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>YASH SHAH</div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}> 80/100</div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>Basic (Trial)</div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>Paid</div>
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className={style.activate}>
                        {' '}
                        <span>Active</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={` ${style.action} ${style.pointer}`}
                        onClick={() => dispatch(GetOraganisationDetails(items.id))}
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977"
                            stroke="#212529"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
                {/* <tr className={style.table_body_content}>
                                    <td>

                                        <div className={style.user_management_table_body_content}>
                                            IMS TEST
                                        </div>
                                    </td>
                                    <td><div className={style.user_management_table_body_content}>
                                        YASH SHAH
                                    </div>
                                    </td>
                                    <td><div className={style.user_management_table_body_content}> 80/100</div></td>
                                    <td >
                                        <div className={style.user_management_table_body_content}>
                                            Basic (Trial)
                                        </div>
                                    </td>
                                    <td >
                                        <div className={style.user_management_table_body_content}>
                                            Paid
                                        </div>
                                    </td>
                                    <td><div className={style.activate}> <span>Active</span></div></td>
                                    <td><div className={` ${style.action} ${style.pointer}`} onClick={() => OrganisationDetail(25)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </div></td>
                                </tr>

                                <tr className={style.table_body_content}>
                                    <td>
                                        <div className={style.user_management_table_body_content}>
                                            IMS TEST
                                        </div>
                                    </td>
                                    <td><div className={style.user_management_table_body_content}>
                                        YASH SHAH
                                    </div>
                                    </td>
                                    <td><div className={style.user_management_table_body_content}> 80/100</div></td>
                                    <td >
                                        <div className={style.user_management_table_body_content}>
                                            Basic (Trial)
                                        </div>
                                    </td>
                                    <td >
                                        <div className={style.user_management_table_body_content}>
                                            Pending &nbsp;
                                            <svg className={style.pending_alert} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04102 9.99935C1.04102 5.07084 5.07084 1.04102 9.99935 1.04102C14.9279 1.04102 18.9577 5.07084 18.9577 9.99935C18.9577 14.9279 14.9279 18.9577 9.99935 18.9577C5.07084 18.9577 1.04102 14.9279 1.04102 9.99935Z" fill="#FFCF00" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.04102C10.3452 6.04102 10.625 6.32084 10.625 6.66602V10.8327C10.625 11.1779 10.3452 11.4577 10 11.4577C9.65482 11.4577 9.375 11.1779 9.375 10.8327V6.66602C9.375 6.32084 9.65482 6.04102 10 6.04102Z" fill="#272727" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16211 13.3333C9.16211 12.8731 9.53521 12.5 9.99544 12.5H10.0029C10.4632 12.5 10.8363 12.8731 10.8363 13.3333C10.8363 13.7936 10.4632 14.1667 10.0029 14.1667H9.99544C9.53521 14.1667 9.16211 13.7936 9.16211 13.3333Z" fill="#272727" />
                                            </svg>


                                        </div>
                                    </td>
                                    <td><div className={style.activate}> <span>Active</span></div></td>
                                    <td><div className={` ${style.action} ${style.pointer}`} onClick={() => OrganisationDetail(25)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </div></td>
                                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        {/*______________________________ PAGINATION ______________________________  */}

        {/* <div className='pagination'>
<div className='number'>
The Page you are on &nbsp; &nbsp; <button className='currentpage'> {currentPage}  </button>
</div>
<div className='line'>
<svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<line opacity="0.1" x1="0.695312" y1="23.5" x2="0.695311" y2="0.5" stroke="#764FDB" stroke-linecap="round" />
</svg>

</div>
<div className='change'>
<button onClick={() => previous()}>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5622 12.7803C10.2635 13.0732 9.7793 13.0732 9.48064 12.7803L5.14685 8.53033C4.84819 8.23744 4.84819 7.76256 5.14685 7.46967L9.48064 3.21967C9.7793 2.92678 10.2635 2.92678 10.5622 3.21967C10.8608 3.51256 10.8608 3.98744 10.5622 4.28033L6.76921 8L10.5622 11.7197C10.8608 12.0126 10.8608 12.4874 10.5622 12.7803Z" fill="#00ABFB" />
</svg>
</button>
<div className='current'>
{offset}-{last} of {totalCount}
</div>
<button onClick={() => next()}>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.63122 3.21967C6.92989 2.92678 7.41413 2.92678 7.7128 3.21967L12.0466 7.46967C12.3452 7.76256 12.3452 8.23744 12.0466 8.53033L7.7128 12.7803C7.41413 13.0732 6.92989 13.0732 6.63122 12.7803C6.33256 12.4874 6.33256 12.0126 6.63122 11.7197L10.4242 8L6.63122 4.28033C6.33256 3.98744 6.33256 3.51256 6.63122 3.21967Z" fill="#00ABFB" />
</svg>
</button>
</div>
</div> */}
        {/*______________________________ PAGINATION ______________________________  */}
      </div>
    </div>
  );
};

export default OrganisationList;
