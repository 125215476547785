import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import QuestionBank from './pages/QuestionBank';
import CreateQuestion from './pages/CreateQuestion';
import EditQuestion from './pages/EditQuestion';
import CreateQuestionSame from './pages/CreateQuestionSame';
import UserManagement from './pages/UserManagement';
import Masters from './pages/Masters';
import OrganisationManagement from './pages/OrganisationManagement';
import Review from './pages/Review';
import Report from './pages/Report';
import TaskManagement from './pages/TaskManagement';
import TestCreation from './pages/TestCreation';
import TestComponent from './component/TestComponent'; // Import TestComponent
import ForgetPassword from './component/molecule/specific/SignInScreens/ForgetPassword';
import Login from './component/molecule/specific/SignInScreens/Login';
import CreateNewPassword from './component/molecule/specific/SignInScreens/CreateNewPassword';

// Define the functional component with correct typing
const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/create-password" element={<CreateNewPassword />} />
        <Route path="/create-question-same/:id" element={<CreateQuestionSame />} />
        <Route path="/question-bank" element={<QuestionBank />} />
        <Route path="/create-question" element={<CreateQuestion />} />
        <Route path="/edit-question" element={<EditQuestion />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/organisation-management" element={<OrganisationManagement />} />
        <Route path="/masters" element={<Masters />} />
        <Route path="/review" element={<Review />} />
        <Route path="/report" element={<Report />} />
        <Route path="/task-management" element={<TaskManagement />} />
        <Route path="/test-creation" element={<TestCreation />} />
        <Route path="/test-component" element={<TestComponent initialCount={0} title="Counter Component" />} />
      </Routes>
    </>
  );
};

export default App;
