import React, { useEffect, useState } from 'react';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_QUESTIOB_TYPE } from '../../../../../action/apiPath';
import SelectType from './SelectType';
import { useSelector } from 'react-redux';
import CreatingQuestionSingle from './CreatingQuestionSingle';
import CreatingQuestionGrouped from './CreationQuestionGrouped';

import style from '../../../../../assets/styles/createQuestion.module.css';

interface QuestionCreationProps {
  id: number;
  getData: (data: any) => void;
}

const QuestionCreation: React.FC<QuestionCreationProps> = ({ id, getData }) => {
  // _______ selector for question type screen _______
  const selector = useSelector((state: any) => state.QuestionScreenReducer);
  const basicInfoForType = useSelector((state: any) => state.BasicInformationReducer);
  // console.log("selector", basicInfoForType)

  const handleQuestionType = () => {};

  // console.log("check",selector,basicInfoForType)

  return (
    <div className={style.select_question}>
      {/*_______ type selection of question bank  _______*/}
      {selector.screen === 2.1 ? (
        <SelectType getData={getData} id={id} data={selector} handleQuestionType={handleQuestionType} />
      ) : (
        ''
      )}
      {/* _______ different question bank according to user selection -> Single Type _______ */}
      {selector?.screen === 2.2 && basicInfoForType?.format === 0 ? (
        <CreatingQuestionSingle getData={getData} id={id} />
      ) : (
        ''
      )}

      {/* _______ different question bank according to user selection -> Grouped Type _______ */}
      {selector?.screen === 2.2 && basicInfoForType?.format === 1 ? (
        <CreatingQuestionGrouped getData={getData} id={id} />
      ) : (
        ''
      )}
    </div>
  );
};

export default QuestionCreation;
